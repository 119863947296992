import { gql } from '@apollo/client';

export const GET_CONTRACT_LINE_HISTORY = gql`
  query getContractLineHistory($contractLineId: Float!) {
    getContractLineHistory(contractLineId: $contractLineId) {
      id
      versionNumber
      modTag
      contractHeaderVersionId
      contractHeaderVersion {
        versionNumber
      }
      createdBy
      createdByUser {
        fullName
        email
      }
      createdAt
      changes
    }
  }
`;

export const GET_CONTRACT_LINE_BY_ID = gql`
  query getContractLineTemplateById($contractLineId: Float!) {
    getContractLineTemplateById(contractLineId: $contractLineId) {
      id
      scheduleLine
      modelYear
      contractHeader {
        contractHeaderId
        contractNumber
        contractYear
        renewalYear
        fleetAwarded
        contractUpiid
        contractEndDate
        solicitationPeriodId
        solicitationId
        solicitation {
          purchaseTypeCode
          contractYear
          solicitationNumber
          solicitationNumberLegacy
          solicitationPeriods {
            id
            startDate
            endDate
            periodType
          }
        }
        vendor {
          id
          vendorName
          uniqueEntityIdentifier
        }
      }
      standardItemId
      standardItem {
        standardItemId: id
        standardItemNumber
        title
        year
      }
      make {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      model {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      chassisMake {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      chassisModel {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      makeModelApproval {
        fleetApprovalStatus
      }
    }
  }
`;
