import * as yup from 'yup';

const contractHeaderSchema = yup.object().shape({
  pocName: yup.string().nullable(),
  pocEmail: yup.string().nullable().email('Point of Contact Email is invalid'),
  pocPhone: yup.string().nullable(),
  estimatedValue: yup
    .number('Dollar Value Maximum Order is not valid')
    .nullable()
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value ?? null;
    }),
});

export default contractHeaderSchema;
