// eslint-disable-next-line import/prefer-default-export
export const usHolidays2024To2034 = [
  '2024-01-01',
  '2024-01-15',
  '2024-02-14',
  '2024-02-19',
  '2024-03-17',
  '2024-03-31',
  '2024-04-15',
  '2024-04-24',
  '2024-05-12',
  '2024-05-27',
  '2024-06-16',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-10-14',
  '2024-10-31',
  '2024-11-05',
  '2024-11-11',
  '2024-11-28',
  '2024-12-24',
  '2024-12-25',
  '2024-12-31',
  '2025-01-01',
  '2025-01-20',
  '2025-02-14',
  '2025-02-17',
  '2025-03-17',
  '2025-04-15',
  '2025-04-20',
  '2025-04-23',
  '2025-05-11',
  '2025-05-26',
  '2025-06-15',
  '2025-06-19',
  '2025-07-04',
  '2025-09-01',
  '2025-10-13',
  '2025-10-31',
  '2025-11-11',
  '2025-11-27',
  '2025-11-28',
  '2025-12-24',
  '2025-12-25',
  '2025-12-31',
  '2026-01-01',
  '2026-01-19',
  '2026-02-14',
  '2026-02-16',
  '2026-03-17',
  '2026-04-05',
  '2026-04-15',
  '2026-04-22',
  '2026-05-10',
  '2026-05-25',
  '2026-06-19',
  '2026-06-21',
  '2026-07-03',
  '2026-07-04',
  '2026-09-07',
  '2026-10-12',
  '2026-10-31',
  '2026-11-11',
  '2026-11-26',
  '2026-11-27',
  '2026-12-24',
  '2026-12-25',
  '2026-12-31',
  '2027-01-01',
  '2027-01-18',
  '2027-02-14',
  '2027-02-15',
  '2027-03-17',
  '2027-03-28',
  '2027-04-15',
  '2027-04-21',
  '2027-05-09',
  '2027-05-31',
  '2027-06-18',
  '2027-06-19',
  '2027-06-20',
  '2027-07-04',
  '2027-07-05',
  '2027-09-06',
  '2027-10-11',
  '2027-10-31',
  '2027-11-11',
  '2027-11-25',
  '2027-11-26',
  '2027-12-24',
  '2027-12-24',
  '2027-12-25',
  '2027-12-31',
  '2027-12-31',
  '2028-01-01',
  '2028-01-17',
  '2028-02-14',
  '2028-02-21',
  '2028-03-17',
  '2028-04-16',
  '2028-04-18',
  '2028-04-26',
  '2028-05-14',
  '2028-05-29',
  '2028-06-18',
  '2028-06-19',
  '2028-07-04',
  '2028-09-04',
  '2028-10-09',
  '2028-10-31',
  '2028-11-07',
  '2028-11-10',
  '2028-11-11',
  '2028-11-23',
  '2028-11-24',
  '2028-12-24',
  '2028-12-25',
  '2028-12-31',
  '2029-01-01',
  '2029-01-15',
  '2029-02-14',
  '2029-02-19',
  '2029-03-17',
  '2029-04-01',
  '2029-04-17',
  '2029-04-25',
  '2029-05-13',
  '2029-05-28',
  '2029-06-17',
  '2029-06-19',
  '2029-07-04',
  '2029-09-03',
  '2029-10-08',
  '2029-10-31',
  '2029-11-11',
  '2029-11-12',
  '2029-11-22',
  '2029-11-23',
  '2029-12-24',
  '2029-12-25',
  '2029-12-31',
  '2030-01-01',
  '2030-01-21',
  '2030-02-14',
  '2030-02-18',
  '2030-03-17',
  '2030-04-15',
  '2030-04-21',
  '2030-04-24',
  '2030-05-12',
  '2030-05-27',
  '2030-06-16',
  '2030-06-19',
  '2030-07-04',
  '2030-09-02',
  '2030-10-14',
  '2030-10-31',
  '2030-11-11',
  '2030-11-28',
  '2030-12-24',
  '2030-12-25',
  '2030-12-31',
  '2031-01-01',
  '2031-01-20',
  '2031-02-14',
  '2031-02-17',
  '2031-03-17',
  '2031-04-13',
  '2031-04-15',
  '2031-04-23',
  '2031-05-11',
  '2031-05-26',
  '2031-06-15',
  '2031-06-19',
  '2031-07-04',
  '2031-09-01',
  '2031-10-13',
  '2031-10-31',
  '2031-11-11',
  '2031-11-27',
  '2031-11-28',
  '2031-12-24',
  '2031-12-25',
  '2031-12-31',
  '2032-01-01',
  '2032-01-19',
  '2032-02-14',
  '2032-02-16',
  '2032-03-17',
  '2032-03-28',
  '2032-04-15',
  '2032-04-21',
  '2032-05-09',
  '2032-05-31',
  '2032-06-18',
  '2032-06-19',
  '2032-06-20',
  '2032-07-04',
  '2032-07-05',
  '2032-09-06',
  '2032-10-11',
  '2032-10-31',
  '2032-11-02',
  '2032-11-11',
  '2032-11-25',
  '2032-11-26',
  '2032-12-24',
  '2032-12-24',
  '2032-12-25',
  '2032-12-31',
  '2032-12-31',
  '2033-01-01',
  '2033-01-17',
  '2033-02-14',
  '2033-02-21',
  '2033-03-17',
  '2033-04-17',
  '2033-04-18',
  '2033-04-27',
  '2033-05-08',
  '2033-05-30',
  '2033-06-19',
  '2033-06-19',
  '2033-06-20',
  '2033-07-04',
  '2033-09-05',
  '2033-10-10',
  '2033-10-31',
  '2033-11-11',
  '2033-11-24',
  '2033-11-25',
  '2033-12-24',
  '2033-12-25',
  '2033-12-26',
  '2033-12-31',
  '2034-01-01',
  '2034-01-02',
  '2034-01-16',
  '2034-02-14',
  '2034-02-20',
  '2034-03-17',
  '2034-04-09',
  '2034-04-18',
  '2034-04-26',
  '2034-05-14',
  '2034-05-29',
  '2034-06-18',
  '2034-06-19',
  '2034-07-04',
  '2034-09-04',
  '2034-10-09',
  '2034-10-31',
  '2034-11-10',
  '2034-11-11',
  '2034-11-23',
  '2034-11-24',
  '2034-12-24',
  '2034-12-25',
  '2034-12-31',
];
