import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import SingleSelectForm from './components/single-select-form';
import SingleOrMultiSelect from './components/single-or-multi-select';
import {
  getContractTypes,
  REPORTS_ALL,
  REPORTS_VS,
  REPORT_CONFIG,
} from './components/contract-report-types';
import {
  getContractYearOptions,
  getErrObj,
  getReportOptions,
  STEP_1_DEFAULTS,
} from './components/contract-report-helper';
import DateRangeForm from './components/date-range-form';

const ReportSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onNext,
}) => {
  const reportGroups = canViewAll ? REPORTS_ALL : REPORTS_VS;
  const contractTypeOptions = getContractTypes(reportGroups).map((type) => ({
    value: type,
    label: type,
  }));
  const contractYearOptions = getContractYearOptions();
  const reportOptions = getReportOptions(
    reportGroups,
    formValues.contractType.value,
  );

  const reportConfig = REPORT_CONFIG[formValues.report.value] ?? {};

  const onFormValueChange = (field, value, hasError, fieldName, dependency) => {
    setFormValues({
      ...formValues,
      [field]: {
        value,
        errorMessage: hasError
          ? `${fieldName ?? 'This field'} is required`
          : '',
      },
      ...(dependency ?? {}),
    });
  };

  const onContractTypeChange = (value, hasError) => {
    onFormValueChange('contractType', value, hasError, 'Contract type', {
      isActiveContract: false,
      report: { value: '', errorMessage: '' },
      ...STEP_1_DEFAULTS,
    });
  };

  const onReportChange = (value, hasError) => {
    onFormValueChange('report', value, hasError, 'Report', {
      isActiveContract: !!value && !!REPORT_CONFIG[value].isActiveContract,
      ...STEP_1_DEFAULTS,
    });
  };

  const onYearChange = (value, hasError) => {
    onFormValueChange('contractYears', value, hasError, 'Contract year');
  };

  const onDateRangeChange = (field, value) => {
    onFormValueChange(field, value, !value, `${field.substring(11)} date`);
  };

  const onClickNext = () => {
    const {
      report,
      contractYears,
      awardedDateFrom,
      awardedDateTo,
    } = formValues;
    const reportMissing = !report.value;
    const yearMissing =
      (reportConfig.contractYearSS || reportConfig.contractYearMS) &&
      !contractYears.value.length;
    const fromMissing = reportConfig.awardedDateRange && !awardedDateFrom.value;
    const toMissing = reportConfig.awardedDateRange && !awardedDateTo.value;

    if (reportMissing || yearMissing || fromMissing || toMissing)
      setFormValues({
        ...formValues,
        ...getErrObj('report', reportMissing, '', 'Report'),
        ...getErrObj('contractYears', yearMissing, [], 'Contract year'),
        ...getErrObj('awardedDateFrom', fromMissing, '', 'From date'),
        ...getErrObj('awardedDateTo', toMissing, '', 'To date'),
      });
    else onNext();
  };

  return (
    <div className="margin-top-4">
      <SingleSelectForm
        label="Select a contract type"
        field="contractType"
        options={contractTypeOptions}
        formValue={formValues.contractType}
        onFormChange={onContractTypeChange}
      />

      <SingleSelectForm
        label="Select a report"
        field="report"
        options={reportOptions}
        formValue={formValues.report}
        onFormChange={onReportChange}
        required
      />

      {(reportConfig.contractYearSS || reportConfig.contractYearMS) && (
        <div className="grid-col-6">
          <SingleOrMultiSelect
            label="Select contract year"
            field="contractYears"
            options={contractYearOptions}
            formValue={formValues.contractYears}
            onFormChange={onYearChange}
            required
            isMultiSelect={reportConfig.contractYearMS}
          />
        </div>
      )}

      {reportConfig.awardedDateRange && (
        <DateRangeForm
          label="Select contract awarded date range"
          formValues={formValues}
          onFormChange={onDateRangeChange}
          required
        />
      )}

      <div className="margin-top-8">
        <Button
          variant="primary"
          label="Next"
          data-testid="next-button"
          rightIcon={{ name: 'arrow_forward' }}
          onClick={onClickNext}
        />
      </div>
    </div>
  );
};

ReportSelectionForm.defaultProps = {};
ReportSelectionForm.propTypes = {
  canViewAll: PropTypes.bool.isRequired,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default ReportSelectionForm;
