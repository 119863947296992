import React from 'react';
import PropTypes from 'prop-types';
import { Label, RadioButton } from '@gsa/afp-component-library';
import SingleOrMultiSelect from './single-or-multi-select';
import { STEP_2_FORM_DEFAULTS } from './contract-report-helper';

const SolicitationOrVendorForm = ({
  isMultiSelect,
  canViewAll,
  formValues,
  setFormValues,
  selectSolVendor,
  setSelectSolVendor,
  vendorOptions,
  solicitationOptions,
}) => {
  const onFormValueChange = (field, value, hasError, fieldName) => {
    setFormValues((prev) => ({
      ...prev,
      ...STEP_2_FORM_DEFAULTS,
      [field]: {
        value,
        errorMessage: hasError
          ? `${fieldName ?? 'This field'} is required`
          : '',
      },
    }));
  };

  const onSolicitationChange = (value, hasError) => {
    onFormValueChange('solicitations', value, hasError, 'Solicitation');
  };
  const onVendorChange = (value, hasError) => {
    onFormValueChange('vendors', value, hasError, 'Vendor');
  };

  const onSolVendorChange = (selection) => {
    setSelectSolVendor(selection);
    setFormValues((prev) => ({
      ...prev,
      ...STEP_2_FORM_DEFAULTS,
    }));
  };

  return (
    <div>
      <Label required={!isMultiSelect} className="text-bold">
        Select solicitation or vendor to be included in the report
      </Label>
      {canViewAll && (
        <div className="grid-row grid-gap-5">
          <div className="grid-col-3">
            <RadioButton
              name="selectSolVendor"
              label="Solicitation"
              checked={selectSolVendor === 'Solicitation'}
              onClick={() => {
                onSolVendorChange('Solicitation');
              }}
            />
          </div>

          <div className="grid-col-3">
            <RadioButton
              name="selectSolVendor"
              label="Vendor"
              checked={selectSolVendor === 'Vendor'}
              onClick={() => {
                onSolVendorChange('Vendor');
              }}
            />
          </div>
        </div>
      )}

      {selectSolVendor === 'Solicitation' && (
        <SingleOrMultiSelect
          label="Select solicitation"
          field="solicitations"
          options={solicitationOptions}
          formValue={formValues.solicitations}
          onFormChange={onSolicitationChange}
          required={!isMultiSelect}
          isMultiSelect={isMultiSelect}
        />
      )}

      {selectSolVendor === 'Vendor' && (
        <SingleOrMultiSelect
          label="Select vendor"
          field="vendors"
          options={vendorOptions}
          formValue={formValues.vendors}
          onFormChange={onVendorChange}
          required={!isMultiSelect}
          isMultiSelect={isMultiSelect}
        />
      )}
    </div>
  );
};

SolicitationOrVendorForm.propTypes = {
  isMultiSelect: PropTypes.bool.isRequired,
  canViewAll: PropTypes.bool.isRequired,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  selectSolVendor: PropTypes.string.isRequired,
  setSelectSolVendor: PropTypes.func.isRequired,
  vendorOptions: PropTypes.arrayOf(Object).isRequired,
  solicitationOptions: PropTypes.arrayOf(Object).isRequired,
};

export default SolicitationOrVendorForm;
