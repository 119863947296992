import React, { useEffect, useMemo, useState } from 'react';
import { PageTitle } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import ContractSearchSection from './contract-search-section';
import ContractSearchTable from './contract-search-table';
import { SEARCH_OPTIONS } from './contract-search-helpers';
import { GET_CONTRACT_HEADERS_BY_CRITERIA } from './contract-search-gql';
import { ContractSearchBreadcrumbs } from './contract-search-breadcrumbs';
import { getContractYear } from '../components/contract-helpers';

const ContractSearch = () => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [contracts, setContracts] = useState([]);

  const [searchKey, setSearchKey] = useState(SEARCH_OPTIONS[0].value);
  const [searchValue, setSearchValue] = useState('');
  const [searchMessage, setSearchMessage] = useState('');

  const [getContractHeadersByCriteria, { loading, called }] = useLazyQuery(
    GET_CONTRACT_HEADERS_BY_CRITERIA,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setOffset(0);
        setContracts(res.getContractHeadersByCriteria?.rows || []);
      },
    },
  );

  const memoizedContracts = useMemo(() => {
    const contractYearsArray = contracts.map((contract) => ({
      ...contract,
      // NOTE: displayContractYear is used as the accessor for the contract year column
      displayContractYear: getContractYear(contract),
    }));

    const contractYearMap = contractYearsArray.reduce(
      (contractMap, contract) => {
        const existing = contractMap.get(contract.contractUpiid);
        if (
          !existing ||
          existing.displayContractYear < contract.displayContractYear
        ) {
          contractMap.set(contract.contractUpiid, contract);
        }
        return contractMap;
      },
      new Map(),
    );

    return Array.from(contractYearMap.values());
  }, [contracts]);

  useEffect(() => {
    setSearchMessage(
      memoizedContracts.length === 0
        ? `There are no contracts matching the search criteria.`
        : `Search results for ${searchKey} "${searchValue}"`,
    );
  }, [memoizedContracts]);

  const onSearch = () => {
    const trimmedSearchValue = String(searchValue).trim();
    if (!trimmedSearchValue) {
      return;
    }

    getContractHeadersByCriteria({
      variables: {
        criteria: { [searchKey]: trimmedSearchValue },
        // NOTE: Since we use different fields to display the contract year,
        // we need to fetch all contract headers to get the latest contract year
        limit: 10000,
      },
    });
  };

  const onPageChange = (currentPage, itemsPerPage) => {
    let newOffset;
    if (itemsPerPage === limit) {
      newOffset = (currentPage - 1) * itemsPerPage;
    } else {
      newOffset = 0; // reset to page 1
      setLimit(itemsPerPage);
    }
    setOffset(newOffset);
  };

  return (
    <>
      <div className="margin-bottom-8">
        <ContractSearchBreadcrumbs />
        <div className="grid-row margin-bottom-5 padding-top-1 padding-bottom-1 gap-1">
          <div className="grid-col-10">
            <PageTitle
              title="Contracts"
              aria-label="Contracts"
              className="margin-bottom-0"
            />
            <div>
              Search contracts by contract number, contract uPIID, solicitation
              uPIID, solicitation name and Vendor name/UEI.
            </div>
          </div>
        </div>
        <ContractSearchSection
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onSearch={onSearch}
        />
        {called && !loading && (
          <div className="text-bold margin-top-05">{searchMessage}</div>
        )}
        <ContractSearchTable
          loading={loading}
          data={memoizedContracts.slice(offset, offset + limit)}
          count={memoizedContracts.length}
          offset={offset}
          limit={limit}
          onPageChange={onPageChange}
          onSort={() => {}}
        />
      </div>
    </>
  );
};

export default ContractSearch;
