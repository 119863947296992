/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useParams } from 'react-router-dom';
import { CONTRACT_ACTIONS, ContractActions } from '../contract-actions';

export const ContractHeaderActions = () => {
  const { contractHeaderId } = useParams();

  return (
    <ContractActions
      actions={[
        CONTRACT_ACTIONS.SEARCH_CONTRACTS,
        CONTRACT_ACTIONS.VIEW_CONTRACT_LINE_LISTING,
        CONTRACT_ACTIONS.VIEW_CONTRACT_HISTORY,
      ]}
      contractHeaderId={contractHeaderId}
    />
  );
};
