/* eslint-disable react/prop-types */
import React from 'react';

import { SelectDropdown } from '@gsa/afp-component-library';

import { useSIMakeModelProvider } from './si-make-model-panel-provider';
import TypeAhead from './typeahead';

const SIMakeModelPanel = () => {
  const {
    showChassis,
    requiresChassis,
    bodyMake,
    setBodyMake,
    bodyMakeInputValue,
    setBodyMakeInputValue,
    bodyMakeOptions,
    setBodyMakeOptions,

    bodyModel,
    setBodyModel,
    bodyModelInputValue,
    setBodyModelInputValue,
    bodyModelOptions,
    setBodyModelOptions,

    chassisMake,
    setChassisMake,
    chassisMakeInputValue,
    setChassisMakeInputValue,
    chassisMakeOptions,
    setChassisMakeOptions,

    chassisModel,
    setChassisModel,
    chassisModelInputValue,
    setChassisModelInputValue,
    chassisModelOptions,
    setChassisModelOptions,

    modelYear,
    setModelYear,

    fetchMakes,
    fetchModels,

    getErrors,
    dirtyFields,
    setDirtyFields,
  } = useSIMakeModelProvider();

  const getModelYearRange = (previousYears, nextYears) => {
    const currentYear = new Date().getFullYear();
    const years = [{ value: '', label: '-Select-' }];
    for (
      let i = currentYear - previousYears;
      i <= currentYear + nextYears;
      i += 1
    ) {
      years.push({ value: i, label: i.toString() });
    }
    return years;
  };

  const makeOptionRenderer = (option) => {
    return (
      <div>
        <div>
          <b>Make:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaMakeCode ? option?.nhtsaMakeCode : option.value}
        </div>
      </div>
    );
  };

  const modelOptionRenderer = (option) => {
    return (
      <div>
        <div>
          <b>Model:</b> {option.label}
        </div>
        <div>
          <b>Code:</b>{' '}
          {option?.nhtsaModelCode ? option?.nhtsaModelCode : option.value}
        </div>
      </div>
    );
  };
  const errors = getErrors();
  const bodyMakeError = errors?.find((e) => e.field === 'bodyMake')?.message;
  const bodyModelError = errors?.find((e) => e.field === 'bodyModel')?.message;
  const chassisMakeError = errors?.find((e) => e.field === 'chassisMake')
    ?.message;
  const chassisModelError = errors?.find((e) => e.field === 'chassisModel')
    ?.message;
  const modelYearError = errors?.find((e) => e.field === 'modelYear')?.message;

  return (
    <div className="grid-container">
      <div className="grid-row grid-gap-4 flex-align-end">
        <TypeAhead
          className="grid-col flex-1"
          label="Body make"
          placeholder="Search body make"
          preLoadHelpMsg="Type to search for makes"
          required
          options={bodyMakeOptions || []}
          setOptions={setBodyMakeOptions}
          selectedOption={bodyMake}
          inputValue={bodyMakeInputValue}
          setInputValue={(v) => {
            setBodyMakeInputValue(v);
            if (!v && (bodyModel || bodyModelInputValue)) {
              setBodyModel(undefined);
              setBodyModelInputValue('');
              setBodyModelOptions([]);
            }
          }}
          setSelectedOption={setBodyMake}
          fetchOptions={fetchMakes}
          renderOptionLabel={makeOptionRenderer}
          onBlur={() => {
            setDirtyFields((prev) => [...prev, 'bodyMake']);
          }}
          errorMessage={
            dirtyFields?.includes('bodyMake') && bodyMakeError
              ? bodyMakeError
              : undefined
          }
        />
        <TypeAhead
          className="grid-col flex-1"
          label="Body model"
          placeholder="Search body model"
          preLoadHelpMsg="Type to search for models"
          required
          options={bodyModelOptions || []}
          setOptions={setBodyModelOptions}
          selectedOption={bodyModel}
          inputValue={bodyModelInputValue}
          setInputValue={setBodyModelInputValue}
          setSelectedOption={setBodyModel}
          fetchOptions={(inputValue, signal) => {
            return fetchModels(
              inputValue,
              bodyMake?.value ? parseInt(bodyMake?.value, 10) : -1,
              signal,
            );
          }}
          renderOptionLabel={modelOptionRenderer}
          responseProcessor={(response) => {
            return response.data?.getModelsByPartialMatch?.map((model) => ({
              value: model.value,
              label: model.label,
              fleetApprovalStatus: model?.fleetStatus,
              NHTSApprovalStatus: model?.approvalStatus,
              nhtsaModelCode: model?.nhtsaModelCode,
            }));
          }}
          onBlur={() => {
            setDirtyFields((prev) => [...prev, 'bodyModel']);
          }}
          errorMessage={
            dirtyFields?.includes('bodyModel') && bodyModelError
              ? bodyModelError
              : undefined
          }
        />
        {showChassis && (
          <>
            <TypeAhead
              className="grid-col flex-1"
              label="Chassis make"
              placeholder="Search chassis make"
              preLoadHelpMsg="Type to search for chassis makes"
              required={requiresChassis}
              options={chassisMakeOptions || []}
              setOptions={setChassisMakeOptions}
              selectedOption={chassisMake}
              inputValue={chassisMakeInputValue}
              setInputValue={(v) => {
                setChassisMakeInputValue(v);
                if (!v && (chassisModel || chassisModelInputValue)) {
                  setChassisModel(undefined);
                  setChassisModelInputValue('');
                  setChassisModelOptions([]);
                }
              }}
              setSelectedOption={setChassisMake}
              fetchOptions={fetchMakes}
              renderOptionLabel={makeOptionRenderer}
              onBlur={() => {
                setDirtyFields((prev) => [...prev, 'chassisMake']);
              }}
              errorMessage={
                dirtyFields?.includes('chassisMake') && chassisMakeError
                  ? chassisMakeError
                  : undefined
              }
            />
            <TypeAhead
              className="grid-col flex-1"
              label="Chassis model"
              placeholder="Search chassis model"
              preLoadHelpMsg="Type to search for chassis models"
              required={requiresChassis}
              options={chassisModelOptions || []}
              setOptions={setChassisModelOptions}
              selectedOption={chassisModel}
              inputValue={chassisModelInputValue}
              setInputValue={setChassisModelInputValue}
              setSelectedOption={setChassisModel}
              fetchOptions={(inputValue, signal) => {
                return fetchModels(
                  inputValue,
                  chassisMake?.value ? parseInt(chassisMake?.value, 10) : -1,
                  signal,
                );
              }}
              renderOptionLabel={modelOptionRenderer}
              responseProcessor={(response) => {
                return response.data?.getModelsByPartialMatch?.map((model) => ({
                  value: model.value,
                  label: model.label,
                  fleetApprovalStatus: model?.fleetStatus,
                  NHTSApprovalStatus: model?.approvalStatus,
                  nhtsaModelCode: model?.nhtsaModelCode,
                }));
              }}
              onBlur={() => {
                setDirtyFields((prev) => [...prev, 'chassisModel']);
              }}
              errorMessage={
                dirtyFields?.includes('chassisModel') && chassisModelError
                  ? chassisModelError
                  : undefined
              }
            />
          </>
        )}

        <div className="grid-col flex-1">
          <SelectDropdown
            name="model_year"
            label={<span className="text-bold">Model year</span>}
            required
            onChange={({ target: { value } }) => setModelYear(value)}
            value={modelYear}
            options={getModelYearRange(1, 3)}
            onBlur={() => {
              setDirtyFields((prev) => [...prev, 'modelYear']);
            }}
            errorMessage={
              dirtyFields?.includes('modelYear') && modelYearError
                ? modelYearError
                : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SIMakeModelPanel;
