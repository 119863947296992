import { emDashUnicode } from '../../../utilities/constants';

export const formatDateTime = (value) => {
  if (!value) return emDashUnicode;
  try {
    return Intl.DateTimeFormat('en-US', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(value));
  } catch {
    return emDashUnicode;
  }
};

export const processLineVersionData = (data) => {
  return data.map((version) => ({
    contractVersion: version.contractHeaderVersion.versionNumber,
    contractLineVersion: version.versionNumber,
    modTag: version.modTag ?? emDashUnicode,
    changedBy: version.createdByUser?.fullName ?? version.createdBy,
    changedAt: version.createdAt,
    changeCount: version.changes.length,
    changes: version.changes.map((change) => ({
      ...change,
      category: change.category ?? emDashUnicode,
      equipmentCode: change.equipmentCode ?? emDashUnicode,
    })),
  }));
};

export const filterAndSortVersions = (lineVersions, filters, order) => {
  const getDateStr = (str) => str.substring(0, 10);
  const field = order[0];
  const asc = order[1] === 'ASC' ? -1 : 1;
  return lineVersions
    .filter(
      (v) =>
        (!filters.contractVersion ||
          v.contractVersion === +filters.contractVersion) &&
        (!filters.modTag || v.modTag === filters.modTag) &&
        (!filters.changedBy || v.changedBy === filters.changedBy) &&
        (!filters.changedAtFrom ||
          Number.isNaN(Date.parse(filters.changedAtFrom)) ||
          getDateStr(v.changedAt) >=
            getDateStr(new Date(filters.changedAtFrom).toISOString())) &&
        (!filters.changedAtTo ||
          Number.isNaN(Date.parse(filters.changedAtTo)) ||
          getDateStr(v.changedAt) <=
            getDateStr(new Date(filters.changedAtTo).toISOString())) &&
        (!filters.tab || v.changes.some((c) => c.tab === filters.tab)) &&
        (!filters.equipmentCode ||
          v.changes.some((c) => c.equipmentCode === filters.equipmentCode)) &&
        (!filters.fieldName ||
          v.changes.some((c) => c.fieldName === filters.fieldName)),
    )
    .sort((a, b) => (a[field] < b[field] ? asc : -asc));
};

export const getFilterOptions = (allVersions, field) => {
  const values = new Set();
  allVersions.forEach((v) => {
    if (
      field === 'contractVersion' ||
      field === 'modTag' ||
      field === 'changedBy'
    )
      values.add(v[field]);
    else if (
      field === 'tab' ||
      field === 'equipmentCode' ||
      field === 'fieldName'
    )
      v.changes.forEach((c) => values.add(c[field]));
  });
  return [
    { label: '-Select-', value: '' },
    ...[...values]
      .sort((a, b) => (a < b ? -1 : 1))
      .map((v) => ({ label: v, value: v })),
  ];
};
