/* eslint-disable react/prop-types */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AFPTable } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../utilities/constants';
import { formatDateTime } from './line-history-helpers';

export const getColumns = (
  contractHeaderId,
  contractLineId,
  maxContractHeaderHistoryVersionNumber,
  maxContractLineVersionNumber,
) => [
  {
    Header: 'Contract version',
    accessor: 'contractVersion',
    sortable: true,
    Cell: ({ value }) => {
      if (value === maxContractHeaderHistoryVersionNumber) {
        return (
          <RouterLink
            to={`/catalog/contract/contract-header/${contractHeaderId}/`}
            className="text-bold margin-left-1"
          >
            {value}
          </RouterLink>
        );
      }

      return (
        <RouterLink
          to={`/catalog/contract/contract-header/${contractHeaderId}/history/${value}`}
          className="text-bold margin-left-1"
        >
          {value}
        </RouterLink>
      );
    },
  },
  {
    Header: 'Contract line version',
    accessor: 'contractLineVersion',
    sortable: true,
    Cell: ({ value }) => {
      if (value === maxContractLineVersionNumber) {
        return (
          <RouterLink
            to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/`}
            className="text-bold margin-left-1"
          >
            {value}
          </RouterLink>
        );
      }
      return (
        <RouterLink
          to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history/${value}`}
          className="text-bold margin-left-1"
        >
          {value}
        </RouterLink>
      );
    },
  },
  {
    Header: 'Modification number',
    accessor: 'modTag',
    sortable: true,
  },
  {
    Header: 'Changed by',
    accessor: 'changedBy',
    sortable: true,
  },
  {
    Header: 'Date/time of change',
    accessor: 'changedAt',
    sortable: true,
    Cell: ({ value }) => formatDateTime(value),
  },
  {
    Header: 'Number of changes',
    accessor: 'changeCount',
    sortable: false,
    Cell: ({ value }) => value || emDashUnicode,
  },
];

export const LineHistorySubrow = ({
  row: {
    original: { changes },
  },
}) => {
  return (
    <section className="line-history-subrow">
      <div className="title-s-caps text-primary margin-top-1">
        Contract line version changes
      </div>
      <AFPTable
        data={changes || []}
        columns={[
          {
            Header: 'Change type',
            accessor: 'tab',
            sortable: false,
          },
          {
            Header: 'Equipment category',
            accessor: 'category',
            sortable: false,
          },
          {
            Header: 'Equipment/color code',
            accessor: 'equipmentCode',
            sortable: false,
          },
          {
            Header: 'Field name',
            accessor: 'fieldName',
            sortable: false,
          },
          {
            Header: 'Previous value',
            accessor: 'oldValue',
            sortable: false,
            Cell: ({ value }) => value || emDashUnicode,
          },
          {
            Header: 'Current value',
            accessor: 'newValue',
            sortable: false,
            Cell: ({ value }) => value || emDashUnicode,
          },
        ]}
      />
    </section>
  );
};
