/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  CONTRACT_BREADCRUMBS,
  ContractBreadcrumbs,
} from '../contract-breadcrumbs';

export const ContractSearchBreadcrumbs = () => {
  return (
    <ContractBreadcrumbs
      breadcrumbs={[CONTRACT_BREADCRUMBS.HOME]}
      current={CONTRACT_BREADCRUMBS.CONTRACT_SEARCH}
    />
  );
};
