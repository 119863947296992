import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import { Label, RadioButton } from '@gsa/afp-component-library';
import StandardItemMakeModelPanel from '../make-model/si-make-model-panel';
import {
  useSIMakeModelProvider,
  NON_STANDARD_LINE_TYPES,
} from '../make-model/si-make-model-panel-provider';
import StandardItemSelect from './standard-item-select';

import './add-non-standard-line.scss';
import { useAddContractLineProvider } from './add-contract-line-provider';
import ManufacturerModelNumberTextField from '../components/manufacturer-model-number-text-field';

const AddNonStandardLine = ({ contractHeader }) => {
  const {
    setSelectedStandardItem,
    getErrors,
    setDirtyFields,
    dirtyFields,
  } = useSIMakeModelProvider();
  const {
    setManufacturerModelNumber,
    nonStandardLineType,
    setNonStandardLineType,
  } = useAddContractLineProvider();

  useEffect(() => {
    setNonStandardLineType(NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER);
  }, []);

  const solicitationStandardItems = contractHeader?.solicitation?.programs?.flatMap(
    (program) => {
      const standardItems = program?.solicitationLines?.map((line) => {
        return line?.standardItem;
      });
      return standardItems;
    },
  );
  const options = solicitationStandardItems?.map((s) => ({
    label: `${s.standardItemNumber} - ${s.title}`,
    value: s.id,
  }));
  const errors = getErrors();
  const standardItemError = errors?.find((e) => e.field === 'standardItem')
    ?.message;

  return (
    <div
      id="add-non-standard-line"
      data-testid="add-non-standard-line"
      className="margin-top-4"
    >
      <div className="margin-bottom-4">
        To create a line item, select a standard item and enter a manufacture
        model number or a make/model/year.
      </div>
      <StandardItemSelect
        options={options || []}
        onChange={(evt) => {
          const selectedStandardItemId = evt.target.value;
          const newSelectedStandardItem = solicitationStandardItems.find(
            (s) => s.id === selectedStandardItemId,
          );
          setSelectedStandardItem(newSelectedStandardItem);
        }}
        onBlur={() => {
          setDirtyFields((prev) => uniq([...prev, 'standardItem']));
        }}
        errorMessage={
          dirtyFields?.includes('standardItem') && standardItemError
            ? standardItemError
            : undefined
        }
      />

      <div className="margin-4">
        <Label htmlFor="nonStandardLineType" required>
          Select one of the options below
        </Label>
        <RadioButton
          name="nonStandardLineType"
          label="Manufacturer model number"
          value={NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER}
          checked={
            nonStandardLineType ===
            NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER
          }
          onChange={() => {
            setNonStandardLineType(
              NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER,
            );
          }}
        />
        <RadioButton
          name="nonStandardLineType"
          value={nonStandardLineType}
          label="Make/model/year"
          checked={
            nonStandardLineType === NON_STANDARD_LINE_TYPES.MAKE_MODEL_YEAR
          }
          onChange={() => {
            setNonStandardLineType(NON_STANDARD_LINE_TYPES.MAKE_MODEL_YEAR);
            setManufacturerModelNumber('');
          }}
        />
      </div>

      {nonStandardLineType ===
        NON_STANDARD_LINE_TYPES.MANUFACTURER_MODEL_NUMBER && (
        <div className="options padding-4">
          <ManufacturerModelNumberTextField />
        </div>
      )}
      {nonStandardLineType === NON_STANDARD_LINE_TYPES.MAKE_MODEL_YEAR && (
        <div className="options padding-top-4 padding-bottom-4">
          <StandardItemMakeModelPanel showChassis requiresChassis={false} />
        </div>
      )}
    </div>
  );
};

AddNonStandardLine.propTypes = {
  contractHeader: PropTypes.shape(Object).isRequired,
};

export default AddNonStandardLine;
