import React from 'react';
import { MultiSelectDropdown, Label } from '@gsa/afp-component-library';
import { FormPropTypes, FormDefaultProps } from './form-helper';

const MultiSelectForm = ({
  label,
  field,
  required,
  disabled,
  options,
  formValue,
  onFormChange,
}) => {
  const fieldName = `${field}-multi-select`;
  const onChange = (value) => {
    onFormChange(value, required && formValue.value.length && !value.length);
  };

  return (
    <div
      data-testid={`${fieldName}-wrapper`}
      className={
        formValue.errorMessage
          ? 'grid-col-9 border-left-05 border-secondary-dark padding-left-2 margin-left-neg-2'
          : 'grid-col-9'
      }
    >
      <Label
        className="usa-label text-bold margin-bottom-1"
        htmlFor={fieldName}
        required={required}
      >
        {label}
      </Label>
      {formValue.errorMessage && (
        <div className="text-bold text-secondary-dark margin-bottom-1">
          {formValue.errorMessage}
        </div>
      )}
      <div
        className={
          formValue.errorMessage ? 'border-05 border-secondary-dark' : ''
        }
      >
        <MultiSelectDropdown
          id={fieldName}
          data-testid={fieldName}
          name={fieldName}
          disabled={disabled}
          selectedValues={formValue.value}
          options={options}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

MultiSelectForm.propTypes = FormPropTypes;
MultiSelectForm.defaultProps = FormDefaultProps;

export default MultiSelectForm;
