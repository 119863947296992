/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useParams } from 'react-router-dom';

import { CONTRACT_ACTIONS, ContractActions } from '../contract-actions';

export const ContractLineTemplateActionsPanel = () => {
  const { versionNumber, contractHeaderId, contractLineId } = useParams();

  if (versionNumber) {
    return (
      <ContractActions
        actions={[
          CONTRACT_ACTIONS.VIEW_LATEST_CONTRACT_VERSION,
          CONTRACT_ACTIONS.VIEW_LATEST_CONTRACT_LINE_VERSION,
        ]}
        contractHeaderId={contractHeaderId}
        contractLineId={contractLineId}
      />
    );
  }

  return (
    <ContractActions
      actions={[
        CONTRACT_ACTIONS.VIEW_CONTRACT_LINE_LISTING,
        CONTRACT_ACTIONS.VIEW_CONTRACT,
        CONTRACT_ACTIONS.VIEW_CONTRACT_LINE_HISTORY,
      ]}
      contractHeaderId={contractHeaderId}
      contractLineId={contractLineId}
    />
  );
};
