/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  CONTRACT_BREADCRUMBS,
  ContractBreadcrumbs,
} from '../contract-breadcrumbs';

export const ContractLineTemplateBreadcrumbs = ({ scheduleLine }) => {
  const { contractHeaderId, versionNumber, contractLineId } = useParams();

  if (versionNumber) {
    const scheduleLineLabel = [
      CONTRACT_BREADCRUMBS.CONTRACT_LINE.label,
      scheduleLine,
    ]
      .filter(Boolean)
      .join(' ');

    return (
      <ContractBreadcrumbs
        breadcrumbs={[
          CONTRACT_BREADCRUMBS.HOME,
          CONTRACT_BREADCRUMBS.CONTRACT_SEARCH,
          CONTRACT_BREADCRUMBS.CONTRACT_HEADER,
          CONTRACT_BREADCRUMBS.CONTRACT_LINE_LISTING,
          { ...CONTRACT_BREADCRUMBS.CONTRACT_LINE, label: scheduleLineLabel },
          CONTRACT_BREADCRUMBS.CONTRACT_LINE_HISTORY,
        ]}
        current={CONTRACT_BREADCRUMBS.CONTRACT_LINE_HISTORY_VERSION}
        contractHeaderId={contractHeaderId}
        versionNumber={versionNumber}
        contractLineId={contractLineId}
      />
    );
  }

  const scheduleLineLabel = [
    CONTRACT_BREADCRUMBS.CONTRACT_LINE.label,
    scheduleLine,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <ContractBreadcrumbs
      breadcrumbs={[
        CONTRACT_BREADCRUMBS.HOME,
        CONTRACT_BREADCRUMBS.CONTRACT_SEARCH,
        CONTRACT_BREADCRUMBS.CONTRACT_HEADER,
        CONTRACT_BREADCRUMBS.CONTRACT_LINE_LISTING,
      ]}
      current={scheduleLineLabel}
      contractHeaderId={contractHeaderId}
      versionNumber={versionNumber}
      contractLineId={contractLineId}
    />
  );
};

ContractLineTemplateBreadcrumbs.propTypes = {
  scheduleLine: PropTypes.string,
};

ContractLineTemplateBreadcrumbs.defaultProps = {
  scheduleLine: '',
};
