import React from 'react';
import { gql } from '@apollo/client';
import { Link } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../utilities/constants';

export const GET_CONTRACT_HEADER_HISTORY = gql`
  query getContractHeaderHistory($contractHeaderId: String!) {
    getContractHeaderHistory(contractHeaderId: $contractHeaderId) {
      id
      versionNumber
      createdBy
      createdByUser {
        fullName
        email
      }
      createdAt
      changes
    }
  }
`;

export const formatDateTime = (value) => {
  if (!value) return emDashUnicode;
  try {
    return Intl.DateTimeFormat('en-US', {
      dateStyle: 'short',
      timeStyle: 'short',
    }).format(new Date(value));
  } catch {
    return emDashUnicode;
  }
};

export const formatDollar = (value) => {
  const f = parseFloat(value);
  if (Number.isNaN(f)) return emDashUnicode;
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }).format(f);
};

export const getVendorLink = (vendorId) => {
  if (!vendorId) return emDashUnicode;
  return (
    <Link href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendorId}`}>
      {vendorId}
    </Link>
  );
};

export const HeaderVersionFieldList = [
  { key: 'contractYear', label: 'Contract year' },
  { key: 'vendorId', label: 'Vendor' },
  { key: 'contractPeriod', label: 'Period' },
  { key: 'ueiContractor', label: 'Contractor UEI' },
  { key: 'contractingOfficerName', label: 'Contracting officer name' },
  { key: 'contractingOfficerEmail', label: 'Contracting officer email' },
  { key: 'contractSpecialistName', label: 'Contract specialist name' },
  { key: 'contractSpecialistEmail', label: 'Contract specialist email' },
  {
    key: 'awardCancelledDate',
    label: 'Award cancelled date',
    converter: formatDateTime,
  },
  {
    key: 'contractEndDate',
    label: 'Contract end date',
    converter: formatDateTime,
  },
  {
    key: 'estimatedValue',
    label: 'Contract maximum value',
    converter: formatDollar,
  },
  { key: 'contractUnits', label: 'Contract units' },
];

export const getFieldValue = (value, converter) => {
  if (value == null) return emDashUnicode;
  return converter ? converter(value) : value;
};

export const processChanges = (changes) => {
  return changes.map((change) => {
    const field = HeaderVersionFieldList.find(
      (f) => f.key === change.fieldName,
    );
    if (field)
      return {
        fieldName: field.label,
        previousValue: getFieldValue(change.previous, field.converter),
        currentValue: getFieldValue(change.current, field.converter),
      };
    return {
      fieldName: 'Unknown',
      previousValue: getFieldValue(change.previous),
      currentValue: getFieldValue(change.current),
    };
  });
};

export const processHeaderVersions = (versions) => {
  return versions.map(
    ({ versionNumber, changes, createdAt, createdBy, createdByUser }) => {
      return {
        versionNumber,
        changedBy: createdByUser?.fullName ?? createdBy,
        changedByEmail: createdByUser?.email,
        changedAt: createdAt,
        changes: processChanges(changes),
        changeCount: changes.length,
      };
    },
  );
};

export const filterAndSortVersions = (
  contractHeaderVersions,
  filters,
  order,
) => {
  const getDateStr = (str) => str.substring(0, 10);
  const field = order[0];
  const asc = order[1] === 'ASC' ? -1 : 1;
  return contractHeaderVersions
    .filter(
      (v) =>
        (!filters.versionNumber ||
          v.versionNumber === +filters.versionNumber) &&
        (!filters.fieldName ||
          v.changes.some((c) => c.fieldName === filters.fieldName)) &&
        (!filters.changedBy || v.changedBy === filters.changedBy) &&
        (!filters.changedAtFrom ||
          Number.isNaN(Date.parse(filters.changedAtFrom)) ||
          getDateStr(v.changedAt) >=
            getDateStr(new Date(filters.changedAtFrom).toISOString())) &&
        (!filters.changedAtTo ||
          Number.isNaN(Date.parse(filters.changedAtTo)) ||
          getDateStr(v.changedAt) <=
            getDateStr(new Date(filters.changedAtTo).toISOString())),
    )
    .sort((a, b) => (a[field] < b[field] ? asc : -asc));
};

export const getFilterOptions = (allVersions, field) => {
  const values = new Set();
  allVersions.forEach((v) => {
    if (field === 'versionNumber' || field === 'changedBy')
      values.add(v[field]);
    else if (field === 'fieldName')
      v.changes.forEach((c) => values.add(c.fieldName));
  });
  return [
    { label: '-Select-', value: '' },
    ...[...values]
      .sort((a, b) => (a < b ? -1 : 1))
      .map((v) => ({ label: v, value: v })),
  ];
};
