import { isContractSOP } from '../../components/contract-helpers';

export const LineVersionFlag = {
  NoChange: 0,
  Update: 1,
  Version: 2,
  OptMod: 3,
  Mod: 4,
};

// SOP tab fields
const LineTabFields = [
  { key: 'makeCode', flag: LineVersionFlag.Mod, hideDelta: true },
  { key: 'modelCode', flag: LineVersionFlag.Mod, hideDelta: true },
  { key: 'chassisMakeCode', flag: LineVersionFlag.Mod, hideDelta: true },
  { key: 'chassisModelCode', flag: LineVersionFlag.Mod, hideDelta: true },
  { key: 'makeName', flag: LineVersionFlag.Mod },
  { key: 'modelName', flag: LineVersionFlag.Mod },
  {
    key: 'chassisMakeName',
    flag: LineVersionFlag.Mod,
  },
  {
    key: 'chassisModelName',
    flag: LineVersionFlag.Mod,
  },
  { key: 'modelYear', flag: LineVersionFlag.OptMod },
  { key: 'quantity', flag: LineVersionFlag.Version },
  {
    key: 'isDirect',
    flag: LineVersionFlag.Version,
  },
];
const LineFinancialFields = [
  {
    key: 'unitPrice',
    flag: LineVersionFlag.Mod,
  },
  { key: 'estimatedFleetQuantity', flag: LineVersionFlag.Version },
  { key: 'estimatedTotalQuantity', flag: LineVersionFlag.Version },
];

const LineDetailFields = [
  { key: 'shipmentDays', flag: LineVersionFlag.Version },
  { key: 'shipmentJustification', flag: LineVersionFlag.Version },
];

const MinReqFields = [
  { key: 'value', flag: LineVersionFlag.Version },
  // { key: 'comply', flag: LineVersionFlag.Version },
  { key: 'exception', flag: LineVersionFlag.OptMod },
  { key: 'explanation', flag: LineVersionFlag.Version },
];

const OptEqFields = [
  { key: 'optionType', flag: LineVersionFlag.OptMod },
  {
    key: 'unitPrice',
    flag: LineVersionFlag.OptMod,
  },
  { key: 'additionalShippingDays', flag: LineVersionFlag.Version },
  { key: 'maxQuantity', flag: LineVersionFlag.Version },
  { key: 'exception', flag: LineVersionFlag.OptMod },
  { key: 'explanation', flag: LineVersionFlag.Version },
  {
    key: 'includes',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'excludes',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'requires',
    flag: LineVersionFlag.Version,
  },
];

const EngineFields = [
  { key: 'engineModel', flag: LineVersionFlag.Version },
  {
    key: 'fuelTypeCodeId',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'deliveryRegionType',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'deliveryStates',
    flag: LineVersionFlag.Version,
  },
  { key: 'cylinders', flag: LineVersionFlag.Version },
  { key: 'engineLitres', flag: LineVersionFlag.Version },
  { key: 'range', flag: LineVersionFlag.Version },
  { key: 'rangeElectric', flag: LineVersionFlag.Version },
  { key: 'explanation', flag: LineVersionFlag.Version },
];
const EngineMpgFields = [
  { key: 'city', flag: LineVersionFlag.Version },
  { key: 'highway', flag: LineVersionFlag.Version },
  { key: 'combined', flag: LineVersionFlag.Version },
  { key: 'gpmi', flag: LineVersionFlag.Version },
];
const EngineChargingFields = [
  {
    key: 'type',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'isFastChargeCapable',
    flag: LineVersionFlag.Version,
  },
  {
    key: 'bkwh',
    flag: LineVersionFlag.Version,
  },
];

// Non-SOP tab fields
const NonSopLineTabFields = [
  { key: 'makeCode', flag: LineVersionFlag.OptMod, hideDelta: true },
  { key: 'modelCode', flag: LineVersionFlag.OptMod, hideDelta: true },
  { key: 'chassisMakeCode', flag: LineVersionFlag.OptMod, hideDelta: true },
  { key: 'chassisModelCode', flag: LineVersionFlag.OptMod, hideDelta: true },
  { key: 'makeName', flag: LineVersionFlag.OptMod },
  { key: 'modelName', flag: LineVersionFlag.OptMod },
  {
    key: 'chassisMakeName',
    flag: LineVersionFlag.OptMod,
  },
  {
    key: 'chassisModelName',
    flag: LineVersionFlag.OptMod,
  },
  { key: 'modelYear', flag: LineVersionFlag.OptMod },
  { key: 'manufacturerModelNumber', flag: LineVersionFlag.Mod },
  { key: 'quantity', flag: LineVersionFlag.Mod },
  { key: 'shipmentOrDelivery', flag: LineVersionFlag.OptMod },
  { key: 'deliveryDate', flag: LineVersionFlag.Version },
  { key: 'additionalInformation', flag: LineVersionFlag.Version },
  { key: 'coFirstName', flag: LineVersionFlag.Mod },
  { key: 'coLastName', flag: LineVersionFlag.Mod },
  { key: 'coEmail', flag: LineVersionFlag.Mod },
];
const NonSopLineFinancialFields = [
  {
    key: 'unitPrice',
    flag: LineVersionFlag.Mod,
  },
];

const NonSopLineDetailFields = [
  { key: 'shipmentDays', flag: LineVersionFlag.Mod },
];

// Contract Line Version Helpers
const getObjectVersionFlag = (objectInput, objectFields, procSubfield) => {
  if (!objectInput || Object.keys(objectInput).length === 0)
    return LineVersionFlag.NoChange;
  const flag = Math.max(
    ...objectFields.map((field) =>
      objectInput[field.key] ? field.flag : LineVersionFlag.NoChange,
    ),
    ...(procSubfield ? procSubfield(objectInput) : []),
  );
  return flag || LineVersionFlag.Update;
};

const getArrayVersionFlag = (arrayInput, objectFields, procSubfield) => {
  if (!arrayInput?.length) return LineVersionFlag.NoChange;
  const flag = Math.max(
    ...arrayInput.map((input) =>
      getObjectVersionFlag(input, objectFields, procSubfield),
    ),
  );
  return flag || LineVersionFlag.Update;
};

const getLineTabSubfieldFlag = (lineData) => {
  return [
    getObjectVersionFlag(
      lineData.contractLineFinancialRef,
      LineFinancialFields,
    ),
  ];
};

const getLineDetailSubfieldFlag = (lineDetail) => {
  return [
    ...(lineDetail.associatedLocations ?? []).map(
      () => LineVersionFlag.Version,
    ),
    ...(lineDetail.clarifications ?? []).map(() => LineVersionFlag.Version),
  ];
};

const getEngineSubfieldFlag = (engine) => {
  return [
    getArrayVersionFlag(engine.mpgs, EngineMpgFields),
    ...EngineChargingFields.map((field) =>
      engine.charging?.[field.key] ? field.flag : LineVersionFlag.NoChange,
    ),
    ...(engine.charging?.fcTypes ?? []).map(() => LineVersionFlag.Version),
  ];
};

const getColorVersionFlag = (colorInput) => {
  if (!colorInput?.length) return LineVersionFlag.NoChange;
  return Math.max(
    ...colorInput.map((input) => {
      // delete color
      if (input.isDeleted) return LineVersionFlag.Version;
      // add new color
      if (
        input.standardPremiumCode &&
        input.standardPremiumCode.oldValue == null
      )
        return input.standardPremiumCode.newValue === 'Standard'
          ? LineVersionFlag.Version // Standard
          : LineVersionFlag.Mod; // Optional
      // update color
      return input.price ? LineVersionFlag.Mod : LineVersionFlag.Version;
    }),
  );
};

export const getLineVersionFlag = (contractLineInput) => {
  // Non-SOP
  if (!isContractSOP(contractLineInput.purchaseType))
    return Math.max(
      getObjectVersionFlag(contractLineInput.lineTabData, NonSopLineTabFields),
      getObjectVersionFlag(
        contractLineInput.lineTabData?.contractLineFinancialRef,
        NonSopLineFinancialFields,
      ),
      getObjectVersionFlag(
        contractLineInput.lineDetailTabData,
        NonSopLineDetailFields,
        getLineDetailSubfieldFlag,
      ),
    );

  // SOP logic
  return Math.max(
    getObjectVersionFlag(
      contractLineInput.lineTabData,
      LineTabFields,
      getLineTabSubfieldFlag,
    ),
    getObjectVersionFlag(
      contractLineInput.lineDetailTabData,
      LineDetailFields,
      getLineDetailSubfieldFlag,
    ),
    getArrayVersionFlag(contractLineInput.MinReq, MinReqFields),
    getArrayVersionFlag(contractLineInput.OptEq, OptEqFields),
    getArrayVersionFlag(
      contractLineInput.Engine,
      EngineFields,
      getEngineSubfieldFlag,
    ),
    getColorVersionFlag(contractLineInput.colorTabData),
  );
};
