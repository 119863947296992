import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Modal,
  connectModal,
  Button,
  AccordionItem,
  Checkbox,
  CounterTag,
  Alert,
  TextInput,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../../components/overlay-spinner';
import { processAwardedBidLines } from './helpers';
import {
  GET_CONTRACT_LINES_FOR_IMPORT,
  GET_AWARDED_BID_LINES,
  IMPORT_BID_LINES_TO_CONTRACT,
} from '../lines-query';
import ImportResultsMessage from './import-results-message';

const MOD_TAG_ERROR = 'Modification number is required';

const ImportContractLineModal = ({
  contractHeader,
  isOpen,
  onClose,
  setAlert,
  refetchLines,
  refetchHeader,
}) => {
  const [newLines, setNewLines] = useState([]);
  const [selectedNewLineIds, setSelectedNewLineIds] = useState([]);
  const [existingLines, setExistingLines] = useState([]);
  const [selectedExistingLineIds, setSelectedExistingLineIds] = useState([]);
  const [importingMessage, setImportingMessage] = useState(null);
  const [modTag, setModTag] = useState('');
  const [modTagError, setModTagError] = useState('');
  const [expandedNew, setExpandedNew] = useState(false);
  const [expandedExist, setExpandedExist] = useState(false);

  const totalSelected =
    selectedNewLineIds.length + selectedExistingLineIds.length;
  const {
    contractHeaderId,
    solicitationId,
    solicitation: { solicitationNumber } = {},
    vendorId,
  } = contractHeader;

  const { loading: loadingContractLines, data: contractLineData } = useQuery(
    GET_CONTRACT_LINES_FOR_IMPORT,
    {
      variables: { contractHeaderId },
      skip: !contractHeaderId,
      fetchPolicy: 'network-only',
      onError: onClose,
    },
  );

  const { loading: loadingBidLines, data: bidLineData } = useQuery(
    GET_AWARDED_BID_LINES,
    {
      variables: { solicitationId, vendorId, solicitationNumber },
      skip: !solicitationId || !vendorId || !solicitationNumber,
      fetchPolicy: 'network-only',
      onError: onClose,
    },
  );

  useEffect(() => {
    if (contractLineData && bidLineData) {
      const data = processAwardedBidLines(
        bidLineData.getAwardedBidLinesDetails,
        contractLineData.getContractLinesByCriteria.rows,
      );
      setNewLines(data.newLines);
      setExistingLines(data.existingLines);
    }
  }, [contractLineData, bidLineData]);

  const getErrMsg = (result) => {
    if (result.apiError) return `API call failed: ${result.apiError}`;
    return result.error;
  };

  const getLabel = (result) => {
    if (result.contractLineId)
      return existingLines.find((l) => +l.id.split('|')[0] === result.bidLineId)
        .label;
    return newLines.find((l) => +l.id.split('|')[0] === result.bidLineId).label;
  };

  const getAlertType = (nImported, nFailed) => {
    if (nFailed === 0) return 'success';
    if (nImported === 0) return 'error';
    return 'info';
  };

  const [
    doImport,
    { loading: importing },
  ] = useMutation(IMPORT_BID_LINES_TO_CONTRACT, { onError: () => {} });

  const onSubmit = async () => {
    if (selectedNewLineIds.length + selectedExistingLineIds.length === 0)
      return;

    const importLines = [
      ...selectedNewLineIds.map((id) => ({
        bidLineId: +id.substring(0, id.length - 1),
        label: newLines.find((l) => l.id === id).label,
      })),
      ...selectedExistingLineIds.map((id) => {
        const [bidLineId, contractLineId] = id.split('|');
        return {
          bidLineId: +bidLineId,
          contractLineId: +contractLineId,
          label: existingLines.find((l) => l.id === id).label,
        };
      }),
    ];

    const responses = [];
    // eslint-disable-next-line
    for (let i = 0; i < importLines.length; i += 1) {
      const { label, bidLineId, contractLineId } = importLines[i];
      const variables = {
        contractHeaderId,
        bidLineId,
        contractLineId,
        modTag: modTag || undefined,
      };
      setImportingMessage(
        <div className="text-center">
          Importing line {i + 1} of {importLines.length} ... <br />
          {label}
        </div>,
      );
      try {
        // eslint-disable-next-line
        const res = await doImport({ variables });
        responses.push(res.data.importBidLinesToContract);
      } catch (err) {
        responses.push({ ...variables, apiError: err.message });
      }
    }
    setImportingMessage(null);

    const results = responses.map((res) => {
      const isNew = !res.contractLineId;
      const label = getLabel(res);
      const errorMessage = getErrMsg(res);
      return { isNew, label, errorMessage };
    });
    const nImported = results.filter((res) => !res.errorMessage).length;
    const nFailed = results.filter((res) => res.errorMessage).length;
    const alertType = getAlertType(nImported, nFailed);
    setAlert({
      type: alertType,
      message: <ImportResultsMessage results={results} />,
    });
    onClose();
    if (nImported > 0) {
      refetchLines();
      refetchHeader();
    }
  };

  const showLineItems = (title, lineItems, selectedIds, setSelectedIds) => {
    const allChecked = lineItems.length === selectedIds.length;
    return (
      <div className="padding-bottom-2">
        <Checkbox
          data-testid={`select-all-${title}`}
          label={`${allChecked ? 'Unselect' : 'Select'} all`}
          checked={lineItems.length === selectedIds.length}
          onChange={({ target }) => {
            if (!target.checked) setSelectedIds([]);
            else setSelectedIds(lineItems.map((item) => item.id));
          }}
        />
        {lineItems.map((item) => {
          const key = `${title}-${item.id}`;
          const checked = selectedIds.includes(item.id);
          return (
            <Checkbox
              key={key}
              id={key}
              data-testid={key}
              label={item.label}
              checked={checked}
              onChange={() => {
                if (checked)
                  setSelectedIds((prevIds) =>
                    prevIds.filter((id) => id !== item.id),
                  );
                else setSelectedIds((prevIds) => [...prevIds, item.id]);
              }}
            />
          );
        })}
      </div>
    );
  };

  const getAccordionTitle = (title, selectedItems) => {
    return (
      <div className="display-flex flex-row flex-justify padding-right-2">
        <div>{title}</div>
        <CounterTag count={selectedItems.length} />
      </div>
    );
  };

  const showLineItemsGroup = (
    title,
    lineItems,
    selectedIds,
    setFn,
    expanded,
    setExpanded,
  ) => {
    // const [expanded, setExpanded] = useState(false);
    if (!lineItems.length) return null;
    return (
      <AccordionItem
        id={title}
        title={getAccordionTitle(title, selectedIds)}
        expanded={expanded}
        content={showLineItems(title, lineItems, selectedIds, setFn)}
        handleToggle={() => setExpanded(!expanded)}
      />
    );
  };

  const onModTagChange = ({ target: { value } }) => {
    setModTag(value);
    setModTagError(value ? '' : MOD_TAG_ERROR);
  };

  return (
    <Modal
      id="copy-line-item-modal"
      variant="extra-large"
      title={
        <div className="title-l">
          Select line items to append to the contract
        </div>
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <div>
          <Button
            data-testid="cancel-append-line-btn"
            variant="unstyled"
            onClick={onClose}
            label="Cancel"
          />
          <Button
            data-testid="append-line-btn"
            className="margin-left-2"
            variant="primary"
            onClick={onSubmit}
            label="Append lines"
          />
        </div>
      }
    >
      {(loadingContractLines || loadingBidLines) && <OverlaySpinner />}
      {importing && <OverlaySpinner message={importingMessage} />}
      <div className="minh-mobile">
        <div className="margin-y-4">
          <Alert slim type="warning">
            You can only import lines when the contract is unpublished. <br />
            Selecting the &apos;<span className="text-bold">Append lines</span>
            &apos; button will unpublish the contract and append the lines.
          </Alert>
        </div>
        <li className="margin-top-2">
          <span className="text-bold">Import new contract lines:</span> Select
          bid lines to append to the contract.
        </li>
        <li className="margin-bottom-2">
          <span className="text-bold">Updates to existing contract lines:</span>{' '}
          Select bid lines to update contract lines. This action will overwrite
          the current version of the contract line(s).
        </li>

        <div className="margin-y-4">
          <div className="margin-bottom-1 text-bold text-primary">
            MODIFICATION NUMBER
          </div>
          <div>
            This update will be saved as a version. If this is a modification,
            please enter a modification number below.
          </div>
          <div className="grid-row">
            <TextInput
              data-testid="modification-number-input"
              aria-label="Enter a modification number"
              aria-required="true"
              required
              value={modTag}
              onChange={onModTagChange}
              errorMessage={modTagError}
            />
            <div className="margin-left-2 padding-top-3">(Optional)</div>
          </div>
        </div>

        <div className="display-flex flex-row flex-justify flex-align-end">
          <div className="text-bold text-primary">SELECT LINE ITEMS</div>
          <div style={{ fontSize: '1.5rem' }}>
            <b>{totalSelected}</b>{' '}
            <span className="text-base">
              Total item{totalSelected > 1 ? 's' : ''}
            </span>
          </div>
        </div>

        {!loadingContractLines &&
          !loadingBidLines &&
          !newLines.length &&
          !existingLines.length && <div>No awarded bid lines to import.</div>}
        {showLineItemsGroup(
          'Import new contract lines',
          newLines,
          selectedNewLineIds,
          setSelectedNewLineIds,
          expandedNew,
          setExpandedNew,
        )}
        {showLineItemsGroup(
          'Updates to existing contract lines',
          existingLines,
          selectedExistingLineIds,
          setSelectedExistingLineIds,
          expandedExist,
          setExpandedExist,
        )}
      </div>
    </Modal>
  );
};

ImportContractLineModal.propTypes = {
  contractHeader: PropTypes.shape({
    contractHeaderId: PropTypes.string.isRequired,
    solicitationId: PropTypes.number.isRequired,
    solicitation: PropTypes.shape({
      solicitationNumber: PropTypes.string.isRequired,
    }).isRequired,
    vendorId: PropTypes.string.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  refetchLines: PropTypes.func.isRequired,
  refetchHeader: PropTypes.func.isRequired,
};

ImportContractLineModal.defaultProps = {
  isOpen: false,
};

export default connectModal(ImportContractLineModal);
