import React from 'react';
import {
  SsMsFormPropTypes,
  SsMsFormDefaultProps,
  DEFAULT_OPTION,
} from './form-helper';
import SingleSelectForm from './single-select-form';
import MultiSelectForm from './multi-select-form';

const SingleOrMultiSelect = ({
  label,
  field,
  required,
  disabled,
  options,
  formValue,
  onFormChange,
  isMultiSelect,
}) => {
  if (isMultiSelect)
    return (
      <MultiSelectForm
        label={label}
        field={field}
        required={required}
        disabled={disabled}
        options={options}
        formValue={formValue}
        onFormChange={onFormChange}
      />
    );

  const valueObj = {
    value: formValue.value[0] ?? '',
    errorMessage: formValue.errorMessage,
  };
  const onSingleChange = (value, hasError) => {
    onFormChange(value ? [value] : [], hasError);
  };
  const ssOptions = [DEFAULT_OPTION, ...options];
  return (
    <SingleSelectForm
      label={label}
      field={field}
      required={required}
      disabled={disabled}
      options={ssOptions}
      formValue={valueObj}
      onFormChange={onSingleChange}
    />
  );
};

SingleOrMultiSelect.propTypes = SsMsFormPropTypes;
SingleOrMultiSelect.defaultProps = SsMsFormDefaultProps;

export default SingleOrMultiSelect;
