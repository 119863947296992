/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Link } from 'react-router-dom';
import { useModal } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import AddContractLineModal from './add-contract-line/add-contract-line-modal';
import ImportContractLineModal from './import-contract-line/import-contract-line-modal';
import { AddContractLineProvider } from './add-contract-line/add-contract-line-provider';
import ErrorModal from './error-modal';
import { OPERATIONS, SUBJECTS } from '../../../utilities/constants';
import { isContractSOP } from '../components/contract-helpers';
import { CONTRACT_ACTIONS, ContractActions } from '../contract-actions';
import { isMediumHeavy } from '../contract-constants';

export const ContractLineListingActions = ({
  contractHeader,
  contractLines,
  setAlert,
  refetchLines,
  refetchHeader,
}) => {
  const { contractHeaderId } = useParams();

  const isSop = isContractSOP(contractHeader?.solicitation?.purchaseTypeCode);

  const {
    isOpen: isAddContractLineModalOpen,
    openModal: setAddContractLineModalOpen,
    closeModal: setAddContractLineModalClose,
  } = useModal();

  const {
    isOpen: isImportContractLineModalOpen,
    openModal: openImportContractLineModal,
    closeModal: closeImportContractLineModal,
  } = useModal();

  const {
    isOpen: isErrorModalOpen,
    openModal: openErrorModal,
    closeModal: closeErrorModal,
  } = useModal();
  const ability = useAppAbility();
  const canUpdateContract =
    !isMediumHeavy(contractHeader?.solicitation?.solicitationNumber) &&
    ability.can(OPERATIONS.Update, SUBJECTS.Contract);

  return (
    <>
      <ContractActions
        actions={[
          CONTRACT_ACTIONS.VIEW_CONTRACT,
          canUpdateContract && {
            ...CONTRACT_ACTIONS.ADD_CONTRACT_LINE,
            onClickHandler: () => {
              if (!isSop && !contractHeader.publishedInFleet) {
                openErrorModal(true);
              } else {
                setAddContractLineModalOpen(true);
              }
            },
          },
          isSop &&
            canUpdateContract && {
              ...CONTRACT_ACTIONS.IMPORT_AWARDED_BID_LINES,
              onClickHandler: openImportContractLineModal,
            },
        ]}
        contractHeaderId={contractHeaderId}
      />
      {isAddContractLineModalOpen && (
        <AddContractLineProvider>
          <AddContractLineModal
            isOpen={isAddContractLineModalOpen}
            onClose={setAddContractLineModalClose}
            contractHeader={contractHeader}
            onCreateSuccess={(contractLine) => {
              setAddContractLineModalClose();
              setAlert({
                type: 'success',
                message: (
                  <>
                    Line item
                    <Link
                      to={`/catalog/contract/${contractHeaderId}/contract-line-template/${contractLine.id}`}
                      className="text-bold margin-left-1"
                    >
                      {contractLine.scheduleLine}
                    </Link>{' '}
                    has been added.
                  </>
                ),
              });
              refetchLines();
            }}
          />
        </AddContractLineProvider>
      )}
      <ImportContractLineModal
        isOpen={isImportContractLineModalOpen}
        contractHeader={contractHeader}
        contractLines={contractLines}
        onClose={closeImportContractLineModal}
        setAlert={setAlert}
        refetchLines={refetchLines}
        refetchHeader={refetchHeader}
      />
      {isErrorModalOpen && (
        <ErrorModal closeModal={closeErrorModal} isOpen={isErrorModalOpen} />
      )}
    </>
  );
};

ContractLineListingActions.propTypes = {
  contractHeader: PropTypes.shape({
    solicitation: PropTypes.shape({
      purchaseTypeCode: PropTypes.string,
      solicitationNumber: PropTypes.string,
    }),
    publishedInFleet: PropTypes.bool,
  }),
  contractLines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAlert: PropTypes.func.isRequired,
  refetchLines: PropTypes.func.isRequired,
  refetchHeader: PropTypes.func.isRequired,
};

ContractLineListingActions.defaultProps = {
  contractHeader: {},
};
