import React from 'react';
import { Spinner, PageTitle, Link, NotFound } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import {
  StandardFieldset,
  StandardFieldsetRow,
  StandardFieldsetRowCol,
} from '../components/standard-fieldset';
import ContractDetails from '../components/contract-details';
import ContractHeaderHistoryVersionActions from './contract-header-history-version-actions';
import { emDashUnicode } from '../../../utilities/constants';
import { GET_CONTRACT_HEADER_VERSION_WITH_HISTORY } from './contract-header-history-version.gql';
import { ContractHeaderHistoryVersionBreadcrumbs } from './contract-header-history-version-breadcrumbs';

const valuesOrEmDash = (...values) => {
  return values.find((value) => value) || emDashUnicode;
};

const ContractHeaderHistoryVersion = () => {
  const { contractHeaderId, versionNumber } = useParams();

  const { loading, data } = useQuery(GET_CONTRACT_HEADER_VERSION_WITH_HISTORY, {
    variables: {
      versionNumber: parseInt(versionNumber, 10),
      contractHeaderId,
    },
    skip: !contractHeaderId || !versionNumber,
  });

  const contractHeaderVersionWithHistory =
    data?.getContractHeaderVersionWithHistory;

  if (loading) {
    return (
      <div id="contract-header">
        <ContractHeaderHistoryVersionBreadcrumbs />
        <Spinner data-testid="contract-header-spinner" size="small" />
      </div>
    );
  }

  if (!contractHeaderVersionWithHistory) {
    return (
      <div id="contract-header">
        <ContractHeaderHistoryVersionBreadcrumbs />
        <NotFound />
      </div>
    );
  }

  const contractHeader =
    contractHeaderVersionWithHistory?.contractHeaderHistory || {};

  return (
    <div id="contract-header">
      <ContractHeaderHistoryVersionBreadcrumbs />
      <div className="view-solicitation" data-testid="view-solicitation-detail">
        <div className="grid-row grid-gap margin-bottom-2">
          <div className="grid-col-10">
            <PageTitle
              title={
                <>
                  Contract uPIID:{' '}
                  {valuesOrEmDash(
                    contractHeader.contractUpiid,
                    contractHeader.solicitation?.solicitationNumber,
                  )}
                </>
              }
            />
            <div>
              <span>Contractor: </span>
              <Link
                href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${contractHeader.vendor?.id}`}
              >
                {contractHeader.vendor?.vendorName}
              </Link>
            </div>
          </div>
          <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
            <ContractHeaderHistoryVersionActions />
          </div>
        </div>

        <ContractDetails contract={contractHeader} />

        <StandardFieldset
          name="vehicle-supplier-information"
          label="Vehicle Supplier Information"
        >
          <StandardFieldsetRow className="margin-bottom-8">
            <StandardFieldsetRowCol
              colWidth={3}
              label="Contractor UEI"
              data={valuesOrEmDash(
                contractHeader.vendor?.uniqueEntityIdentifier,
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Fleet vendor number"
              data={valuesOrEmDash(
                contractHeader.vendor?.fleetVendorNumber,
                contractHeader.vendor?.fleetVendorNo,
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Fleet vendor status"
              data={valuesOrEmDash(contractHeader.vendor?.fleetStatus)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="SAM registration expiration date"
              data={valuesOrEmDash(
                contractHeader.vendor?.registrationExpirationDate
                  ? moment(
                      contractHeader.vendor?.registrationExpirationDate,
                    ).format('L')
                  : '',
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Point of contact"
              data={valuesOrEmDash(contractHeader.pocName)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Email"
              data={valuesOrEmDash(contractHeader.pocEmail)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Phone number"
              data={valuesOrEmDash(contractHeader.pocPhone)}
            />
          </StandardFieldsetRow>
        </StandardFieldset>
        <StandardFieldset name="buyer-information" label="Buyer Information">
          <StandardFieldsetRow className="margin-bottom-8">
            <StandardFieldsetRowCol
              colWidth={3}
              label="Contracting officer"
              data={valuesOrEmDash(contractHeader.contractingOfficerName)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Contracting officer email"
              data={valuesOrEmDash(contractHeader.contractingOfficerEmail)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Contracting specialist"
              data={valuesOrEmDash(contractHeader.contractSpecialistName)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Contracting specialist email"
              data={valuesOrEmDash(contractHeader.contractSpecialistEmail)}
            />
          </StandardFieldsetRow>
        </StandardFieldset>
        <StandardFieldset
          name="additional-contract-information"
          label="Additional Contract Information"
        >
          <StandardFieldsetRow className="margin-bottom-8 grid-gap-4">
            <StandardFieldsetRowCol
              colWidth={3}
              label="Start date"
              data={valuesOrEmDash(
                contractHeader.contractStartDate
                  ? moment(contractHeader.contractStartDate).format('L')
                  : '',
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="End date"
              data={valuesOrEmDash(
                contractHeader.contractEndDate
                  ? moment(contractHeader.contractEndDate).format('L')
                  : '',
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Date last updated"
              data={valuesOrEmDash(
                contractHeader.updatedAt
                  ? moment(contractHeader.updatedAt).format('L')
                  : '',
              )}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Termination date"
              data={valuesOrEmDash(
                contractHeader.awardCancelledDate
                  ? moment(contractHeader.awardCancelledDate).format('L')
                  : '-',
              )}
            />

            <StandardFieldsetRowCol
              colWidth={3}
              label="Contract maximum value"
              data={valuesOrEmDash(contractHeader.estimatedValue)}
            />
            <StandardFieldsetRowCol
              colWidth={3}
              label="Legacy solicitation"
              data={valuesOrEmDash(
                contractHeader.solicitation?.solicitationNumberLegacy,
              )}
            />
            <StandardFieldsetRowCol
              colWidth={2}
              label="Publish"
              data={contractHeader.publishedInFleet ? 'Yes' : 'No'}
            />
          </StandardFieldsetRow>
        </StandardFieldset>
      </div>
    </div>
  );
};

export default ContractHeaderHistoryVersion;
