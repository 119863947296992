import React from 'react';
import {
  ButtonDropdown,
  ButtonDropdownItem,
  FlexView,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

export const CONTRACT_ACTIONS = {
  SEARCH_CONTRACTS: {
    label: 'Search contracts',
    customIcon: true,
    icon: 'search',
    getUrl: () => {
      return `/catalog/contract`;
    },
  },
  VIEW_CONTRACT: {
    label: 'View contract',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId }) => {
      return `/catalog/contract/contract-header/${contractHeaderId}`;
    },
  },
  VIEW_LATEST_CONTRACT_VERSION: {
    label: 'View latest contract version',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId }) => {
      return `/catalog/contract/contract-header/${contractHeaderId}`;
    },
  },
  VIEW_CONTRACT_HISTORY: {
    label: 'View contract history',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId }) => {
      return `/catalog/contract/contract-header/${contractHeaderId}/history`;
    },
  },
  VIEW_CONTRACT_LINE_LISTING: {
    label: 'View contract line listing',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId }) => {
      return `/catalog/contract/contract-header/${contractHeaderId}/lines`;
    },
  },
  VIEW_LATEST_CONTRACT_LINE_VERSION: {
    label: 'View latest contract line version',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId, contractLineId }) => {
      return `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`;
    },
  },
  VIEW_CONTRACT_LINE_HISTORY: {
    label: 'View contract line history',
    customIcon: true,
    icon: 'visibility',
    getUrl: ({ contractHeaderId, contractLineId }) => {
      return `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history`;
    },
  },
  ADD_CONTRACT_LINE: {
    label: 'Add contract line',
    customIcon: true,
    icon: 'add',
    onClickHandler: () => {},
  },
  IMPORT_AWARDED_BID_LINES: {
    label: 'Import awarded bid lines',
    customIcon: true,
    icon: 'arrow_downward',
    onClickHandler: () => {},
  },
};

export const ContractActions = ({
  actions,
  contractHeaderId,
  contractLineId,
}) => {
  const history = useHistory();

  return (
    <FlexView hAlignContent="right">
      <ButtonDropdown
        className="margin-right-0 margin-bottom-1"
        variant="outline"
        label="Actions"
        side="right"
      >
        {actions.filter(Boolean).map((action) => {
          return (
            <React.Fragment key={action.label}>
              {action ? (
                <ButtonDropdownItem
                  className="text-primary hover:bg-blue-5"
                  label={action.label}
                  iconName={action.icon}
                  iconType={action.customIcon && 'custom'}
                  iconFill="#005ea2"
                  onClick={
                    action.onClickHandler ||
                    (() => {
                      history.push(
                        action.getUrl({ contractHeaderId, contractLineId }),
                      );
                    })
                  }
                />
              ) : null}
            </React.Fragment>
          );
        })}
      </ButtonDropdown>
    </FlexView>
  );
};

ContractActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  contractHeaderId: PropTypes.string,
  contractLineId: PropTypes.string,
};

ContractActions.defaultProps = {
  contractHeaderId: undefined,
  contractLineId: undefined,
};
