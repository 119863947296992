import React, { useEffect, useState } from 'react';
import { compact } from 'lodash';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  Spinner,
  FlexView,
  ErrorMessage,
  Button,
  SelectDropdown,
  NotFound,
  useModal,
} from '@gsa/afp-component-library';

import { emDashUnicode } from '../../../utilities/constants';
import ToastMessage from '../../../components/Toast/toast';
import { GET_CONTRACT_LINES_BY_CRITERIA } from '../contract-line/lines-query';
import {
  ContractLineProvider,
  useContractLine,
} from './provider/contract-line-provider';
import SopContractLineTabs from './contract-line-tabs';
import NonSopContractLineTemplate from './non-sop-line-template/non-sop-contract-line-tab';
import LineHeader from './line-header';

import './helpers/contract-line-template.scss';
import OverlaySpinner from '../../../components/overlay-spinner';
import { TABS } from './helpers/constants';
import {
  getOptionalEcList,
  getMinReqSaveObj,
  getOptEqSaveObj,
  isEcTabReady,
  isEngineTabReady,
  getEngineSaveObj,
} from './helpers/ec-helpers';
import {
  getLineDetailTabData,
  getLineTabData,
  getColorsTabData,
} from './helpers/line-helpers';
import { getLineVersionFlag, LineVersionFlag } from './helpers/version-helpers';
import ModificationSelectionModal from './modification-selection-modal/modification-selection-modal';
import { isContractSOP } from '../components/contract-helpers';
import { ContractLineTemplateActionsPanel } from './contract-line-template-actions-panel';
import { ContractLineTemplateBreadcrumbs } from './contract-line-template-breadcrumbs';

const ContractLine = () => {
  const {
    contractLineId,
    contractLine,
    getContractLineQueryResult: query,
    isContractLineInitialized,
    loading,
    saving,
    alert,
    setAlert,
    canUpdateContract,
    canVendorUpdateContract,
    canUpdateContractAsOrderingAdmin,

    saveContractLineMutation,
    lineTabData,
    detailTabData,
    standardECs,
    optionalECs,
    engineData,
    colorsTabData,
    makeAvailableForOrdering,
    contractHeaderId,
  } = useContractLine();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const { isOpen, openModal, closeModal } = useModal();

  const canUpdateContractLine =
    canUpdateContract ||
    canVendorUpdateContract ||
    canUpdateContractAsOrderingAdmin;

  const tabIndex = searchParams.get('tabIndex');
  const [lineItems, setLineItems] = useState([]);
  const [lineInput, setLineInput] = useState(null);
  const [modFlag, setModFlag] = useState(undefined);

  const { data: dataLines } = useQuery(GET_CONTRACT_LINES_BY_CRITERIA, {
    variables: {
      criteria: { contractHeaderId },
      fetchPolicy: 'network-only',
      order: 'scheduleLine DESC',
      limit: 9999,
    },
  });

  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;
  const isSOP = isContractSOP(purchaseTypeCode);

  const [selectedLine, setSelectedLine] = useState(lineItems?.[0]?.value || '');

  const handleSelectedLineUpdate = (id) => {
    window.location.href = `/catalog/contract/${contractHeaderId}/contract-line-template/${id}?tabIndex=${tabIndex}`;
  };
  useEffect(() => {
    const lineItemsData = dataLines?.getContractLinesByCriteria?.rows?.map(
      (item) => ({ value: item.id, label: item.scheduleLine }),
    );

    if (contractLine && lineItemsData) {
      const currentLineItem = {
        value: contractLine.id,
        label: contractLine.scheduleLine,
      };
      const restItems = lineItemsData.filter(
        (item) => item.value !== contractLineId,
      );
      const sortedItems = compact([currentLineItem, ...restItems]);
      setLineItems([...sortedItems]);
    }
  }, [dataLines?.getContractLinesByCriteria?.rows, contractLine]);

  useEffect(() => {
    const { state } = window.history;

    if (state && state.showSuccessMessageOnLoad) {
      setAlert({
        type: 'success',
        message: 'The contract line has been successfully updated.',
      });
      window.history.replaceState(
        { ...state, showSuccessMessageOnLoad: false },
        '',
      );
    }
  }, []);

  const saveContractLineInput = () => {
    const notReadyTabs = [];
    setAlert(null);

    // prepare save api payload
    const contractLineInput = { purchaseType: purchaseTypeCode };

    if (isSOP) {
      const startDateStr =
        lineTabData?.orderStartDate || contractLine.orderStartDate;
      const startDate = startDateStr ? moment.utc(startDateStr).local() : null;
      const endDateStr = lineTabData?.orderEndDate || contractLine.orderEndDate;
      const endDate = endDateStr ? moment.utc(endDateStr).local() : null;
      const isLineOpen = moment().isBetween(startDate, endDate);
      const isLineWillBeOpen = moment().isBefore(startDate);

      if (startDateStr && endDate) {
        if (!endDate.isAfter(startDate)) {
          setAlert({
            type: 'error',
            message:
              'The closeout date/time cannot be prior to the open date/time.',
          });
          return;
        }
      }

      if (isLineOpen || isLineWillBeOpen) {
        if (!isEcTabReady(standardECs)) notReadyTabs.push(TABS[2].heading); // MinReq
        if (!isEcTabReady(optionalECs)) notReadyTabs.push(TABS[3].heading); // OptEq
        if (!isEngineTabReady(engineData)) notReadyTabs.push(TABS[4].heading); // Engine&fuel

        if (notReadyTabs.length) {
          setAlert({
            type: 'error',
            message: `Unable to save. Please complete the following tab(s): ${notReadyTabs.join(
              ', ',
            )}.`,
          });
          return;
        }
      }
    }

    contractLineInput[TABS[0].tabKey] = getLineTabData(
      lineTabData,
      contractLine,
      purchaseTypeCode,
      makeAvailableForOrdering,
    );
    contractLineInput[TABS[1].tabKey] = getLineDetailTabData(
      contractLine,
      detailTabData,
      isSOP,
    );
    contractLineInput[TABS[2].tabKey] = getMinReqSaveObj(
      standardECs,
      contractLineId,
    );
    const ecList = getOptionalEcList(optionalECs);
    contractLineInput[TABS[3].tabKey] = getOptEqSaveObj(
      optionalECs,
      contractLineId,
      ecList,
    );
    contractLineInput[TABS[4].tabKey] = getEngineSaveObj(
      engineData,
      contractLineId,
      ecList,
    );
    contractLineInput[TABS[5].tabKey] = getColorsTabData(
      contractLine.modelColors ?? [],
      colorsTabData,
    );

    const versionFlag = getLineVersionFlag(contractLineInput);

    // no change
    if (versionFlag === LineVersionFlag.NoChange) return;

    if (versionFlag <= LineVersionFlag.Version) {
      // update or version
      saveContractLineMutation({
        variables: {
          contractLineId: parseFloat(contractLineId),
          contractLineInput,
        },
      });
    } else {
      // mod - optional or required
      setLineInput(contractLineInput);
      setModFlag(versionFlag);
      openModal();
    }
  };

  const onSaveWithMod = (modTag) => {
    saveContractLineMutation({
      variables: {
        contractLineId: parseFloat(contractLineId),
        contractLineInput: lineInput,
        modTag: modTag || undefined,
      },
    });
  };

  if (loading) {
    return (
      <>
        <ContractLineTemplateBreadcrumbs
          scheduleLine={contractLine?.scheduleLine}
        />
        <Spinner />
      </>
    );
  }

  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';
  let subHeader = null;
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  if (showManufacturerNumber) {
    subHeader = (
      <div className="title-m">
        <span className=" title-m-bold">Manufacturer model number: </span>
        {contractLine?.manufacturerModelNumber || emDashUnicode}
      </div>
    );
  } else {
    subHeader = (
      <>
        <div className="margin-right-2 title-m">
          <span className=" title-m-bold">Body: </span>
          {contractLine?.make?.makeName || emDashUnicode}{' '}
          {contractLine?.model?.modelName || emDashUnicode}
        </div>
        {contractLine?.chassisMake && (
          <div className="title-m">
            <span className=" title-m-bold">Chassis: </span>
            {contractLine?.chassisMake?.makeName || emDashUnicode}{' '}
            {contractLine?.chassisModel?.modelName || emDashUnicode}
          </div>
        )}
      </>
    );
  }

  if (
    isContractLineInitialized &&
    (!contractLine || Object.keys(contractLine).length === 0)
  ) {
    return (
      <FlexView column>
        <ContractLineTemplateBreadcrumbs
          scheduleLine={contractLine?.scheduleLine}
        />
        <NotFound />
      </FlexView>
    );
  }

  return (
    <FlexView column>
      <FlexView>
        {loading && <Spinner data-testid="contract-line-spinner" />}
        {query?.error && (
          <ErrorMessage>
            An error occurred while loading the contract line data.
          </ErrorMessage>
        )}
      </FlexView>

      {saving && <OverlaySpinner />}

      <ModificationSelectionModal
        isOpen={isOpen}
        contractLineId={parseFloat(contractLineId)}
        modFlag={modFlag}
        onSave={onSaveWithMod}
        onClose={closeModal}
      />

      {contractLine && (
        <>
          <ContractLineTemplateBreadcrumbs
            scheduleLine={contractLine?.scheduleLine}
          />
          {alert && (
            <ToastMessage
              type={alert.type}
              message={alert.message}
              onClose={() => setAlert(null)}
              closable
              className="margin-bottom-2"
            />
          )}

          <FlexView
            vAlignContent="bottom"
            grow
            className="margin-top-2 margin-bottom-2"
          >
            <div className="margin-bottom-2">
              <FlexView vAlignContent="center" className="margin-bottom-1">
                <div className="title-xl">
                  Line Item {contractLine?.scheduleLine}
                </div>
                <SelectDropdown
                  id="line-dropdown"
                  aria-label="line dropdown"
                  className="margin-top-0"
                  containerClassName="margin-top-0 margin-left-2"
                  onChange={(e) => {
                    setSelectedLine(e.target.value);
                    handleSelectedLineUpdate(e.target.value);
                  }}
                  options={lineItems || []}
                  value={selectedLine}
                />
              </FlexView>
              <FlexView className="margin-top-2">{subHeader}</FlexView>
            </div>
            <FlexView grow> </FlexView>
            <ContractLineTemplateActionsPanel />
          </FlexView>

          <LineHeader contractLine={contractLine} />

          <FlexView
            className="margin-top-6 margin-bottom-2"
            vAlignContent="center"
          >
            {canUpdateContractLine && (
              <>
                {isSOP ? (
                  <FlexView grow>
                    Upon making edits within any of the tabs below, please be
                    sure to click &quot;<b>Save all changes</b>&quot; before
                    exiting this line item.
                  </FlexView>
                ) : (
                  <FlexView grow>
                    Upon making edits below, please be sure to click &quot;
                    <b>Save all changes</b>&quot; before exiting this line item.
                  </FlexView>
                )}
                <FlexView>
                  <Button
                    label="Save all changes"
                    variant="primary"
                    onClick={saveContractLineInput}
                  />
                </FlexView>
              </>
            )}
          </FlexView>

          {isSOP ? <SopContractLineTabs /> : <NonSopContractLineTemplate />}
        </>
      )}
    </FlexView>
  );
};

const ContractLineTemplate = () => {
  return (
    <ContractLineProvider>
      <ContractLine />
    </ContractLineProvider>
  );
};

export default ContractLineTemplate;
