import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Label } from '@gsa/afp-component-library';
import { convertDatePickerValue } from './form-helper';

const DateRangeForm = ({ label, required, formValues, onFormChange }) => {
  const fieldName = `report-date-range-select`;
  const onChange = (field, value) => {
    onFormChange(field, convertDatePickerValue(value));
  };

  return (
    <div
      data-testid={`${fieldName}-wrapper`}
      className="report-date-range-form"
    >
      <Label
        className="usa-label text-bold margin-bottom-1"
        htmlFor={fieldName}
        required={required}
      >
        {label}
      </Label>
      <div className="grid-row grid-gap">
        <DatePicker
          id={`${fieldName}-from`}
          data-testid={`${fieldName}-from`}
          aria-label="Awarded date from"
          label="From:"
          defaultValue={formValues.awardedDateFrom.value}
          maxDate={formValues.awardedDateTo.value || undefined}
          errorMessage={formValues.awardedDateFrom.errorMessage}
          onChange={(value) => onChange('awardedDateFrom', value)}
        />
        <DatePicker
          id={`${fieldName}-to`}
          data-testid={`${fieldName}-to`}
          aria-label="Awarded date to"
          label="To:"
          defaultValue={formValues.awardedDateTo.value}
          minDate={formValues.awardedDateFrom.value || undefined}
          errorMessage={formValues.awardedDateTo.errorMessage}
          onChange={(value) => onChange('awardedDateTo', value)}
        />
      </div>
    </div>
  );
};

DateRangeForm.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  formValues: PropTypes.shape(Object).isRequired,
  onFormChange: PropTypes.func.isRequired,
};
DateRangeForm.defaultProps = {
  required: false,
};

export default DateRangeForm;
