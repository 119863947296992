import PropTypes from 'prop-types';

export const FormPropTypes = {
  label: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(Object).isRequired,
  formValue: PropTypes.shape(Object).isRequired,
  onFormChange: PropTypes.func.isRequired,
};
export const FormDefaultProps = {
  required: false,
  disabled: false,
};

export const SsMsFormPropTypes = {
  ...FormPropTypes,
  isMultiselect: PropTypes.bool,
};
export const SsMsFormDefaultProps = {
  ...FormDefaultProps,
  isMultiselect: false,
};

export const DEFAULT_OPTION = {
  label: '-Select-',
  value: '',
};

// conver 'mm/dd/yyyy' to 'yyyy-mm-dd'
export const convertDatePickerValue = (usDateStr) => {
  if (!usDateStr) return '';
  return `${usDateStr.substring(6)}-${usDateStr.substring(
    0,
    2,
  )}-${usDateStr.substring(3, 5)}T00:00:00.000Z`;
};
