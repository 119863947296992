import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import {
  Modal,
  Button,
  AccordionItem,
  Checkbox,
  CounterTag,
} from '@gsa/afp-component-library';
import { useRecoilState } from 'recoil';
import OverlaySpinner from '../../../../components/overlay-spinner';
import {
  bidSolicitationAtom,
  bidsDashboardMessageAtom,
} from '../../../bids/atoms/bids-atoms';
import { SOLICITATION_TYPES } from '../../../solicitation/constants';
import {
  GET_STANDARD_ITEM_IDS_BY_SIN,
  GET_BID_LINES_FOR_IMPORT,
  GET_CONTRACT_LINES_FOR_IMPORT,
  IMPORT_CONTRACT_BID_LINES,
  processContractLineData,
  processBidLineData,
} from './helpers';
import { COPY_TYPE } from '../../bid-line-details/copy-line-item/helpers';
import CanIChangeBidData from '../../../bids/components/protect-bid-data-crud';
import ImportResultsMessage from './import-results-message';

const ImportStandardItemModal = ({ standardItem, onClose }) => {
  const [contLines, setContLines] = useState([]);
  const [selectedContLineIds, setSelectedContLineIds] = useState([]);
  const [bidLines, setBidLines] = useState([]);
  const [selectedBidLineIds, setSelectedBidLineIds] = useState([]);
  const [importingMessage, setImportingMessage] = useState(null);

  const [, setToaster] = useRecoilState(bidsDashboardMessageAtom);
  const [bidSolicitation] = useRecoilState(bidSolicitationAtom);
  const { contractYear } = bidSolicitation;

  const [
    loadBidLines,
    { loading: loadingBidLines, data: bidLineData },
  ] = useLazyQuery(GET_BID_LINES_FOR_IMPORT, {
    fetchPolicy: 'network-only',
    onError: () => onClose(),
  });

  const [
    loadContractLines,
    { loading: loadingContractLines, called, data: contractLineData },
  ] = useLazyQuery(GET_CONTRACT_LINES_FOR_IMPORT, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getContractLinesForImport }) => {
      setContLines(
        processContractLineData(getContractLinesForImport, standardItem),
      );
    },
    onError: () => onClose(),
  });

  const { loading: loadingSIs } = useQuery(GET_STANDARD_ITEM_IDS_BY_SIN, {
    variables: { sin: standardItem.standardItemNumber },
    fetchPolicy: 'network-only',
    onCompleted: ({ getStandardItemIdsBySIN: standardItemIds }) => {
      loadBidLines({
        variables: {
          vendorId: standardItem.vendorId,
          standardItemIds,
          contractYears: [contractYear, contractYear - 1],
          destBidId: standardItem.bidId,
        },
      });
      loadContractLines({
        variables: {
          vendorId: standardItem.vendorId,
          standardItemIds,
          contractYears: [contractYear, contractYear - 1],
        },
      });
    },
    onError: () => onClose(),
  });

  useEffect(() => {
    if (called && !loadingContractLines && contractLineData && bidLineData)
      setBidLines(
        processBidLineData(
          bidLineData.getBidLinesForImport,
          contractLineData.getContractLinesForImport,
          standardItem,
        ),
      );
  }, [called, loadingContractLines, contractLineData, bidLineData]);

  const getErrMsg = (result) => {
    if (result.createError)
      return `Unable to create Line Item: ${result.createError}`;
    if (result.copyError)
      return `${
        result.toCreateBidLineId ? 'Line Item created but unable' : 'Unable'
      } to copy to the Line Item: ${result.copyError}`;
    return undefined;
  };

  const getLabel = (result) => {
    if (result.fromContractLineId)
      return contLines.find((l) => +l.id === result.fromContractLineId).label;
    return bidLines.find((l) => +l.id === result.fromBidLineId).label;
  };

  const getAlertType = (imported, failed) => {
    if (!failed.length) return 'success';
    if (!imported.length) return 'error';
    return 'info';
  };

  const [
    doImport,
    { loading: importing },
  ] = useMutation(IMPORT_CONTRACT_BID_LINES, { onError: () => {} });

  const onSubmit = async (copyType) => {
    if (selectedContLineIds.length + selectedBidLineIds.length === 0) return;

    const importLines = [
      ...selectedContLineIds.map((id) => contLines.find((l) => l.id === id)),
      ...selectedBidLineIds.map((id) => bidLines.find((l) => l.id === id)),
    ];

    const responses = [];
    // eslint-disable-next-line
    for (let i = 0; i < importLines.length; i += 1) {
      const line = importLines[i];
      const input = {
        copyType,
        isRecompete:
          bidSolicitation.solicitationType === SOLICITATION_TYPES.Recompete,
        importLines: [line.variables],
      };
      setImportingMessage(
        <div className="text-center">
          Importing line {i + 1} of {importLines.length} ... <br />
          {line.label}
        </div>,
      );
      // eslint-disable-next-line
      const res = await doImport({ variables: { input } });
      if (res) responses.push(...res.data.importContractBidLines);
      else responses.push({ ...line.variables, copyError: 'API call failed' });
    }
    setImportingMessage(null);

    const results = responses.map((res) => {
      const errorMessage = getErrMsg(res);
      const label = getLabel(res);
      const refetch =
        !errorMessage || errorMessage.startsWith('Line Item created') !== null;
      return { label, errorMessage, refetch };
    });
    const imported = results.filter((res) => !res.errorMessage);
    const failed = results.filter((res) => res.errorMessage);
    const alertType = getAlertType(imported, failed);
    const toRefetch = results.some((res) => res.refetch);
    setToaster({
      type: alertType,
      message: (
        <ImportResultsMessage
          imported={imported}
          failed={failed}
          standardItem={standardItem}
        />
      ),
    });
    onClose(toRefetch);
  };

  const showLineItems = (title, lineItems, selectedIds, setSelectedIds) => {
    const allChecked = lineItems.length === selectedIds.length;
    return (
      <div>
        <Checkbox
          data-testid={`select-all-${title}`}
          label={`${allChecked ? 'Unselect' : 'Select'} all`}
          checked={lineItems.length === selectedIds.length}
          onChange={({ target }) => {
            if (!target.checked) setSelectedIds([]);
            else setSelectedIds(lineItems.map((item) => item.id));
          }}
        />
        {lineItems.map((item) => {
          const key = `${title}-${item.id}`;
          const checked = selectedIds.includes(item.id);
          return (
            <Checkbox
              key={key}
              id={key}
              data-testid={key}
              label={item.label}
              checked={checked}
              onChange={() => {
                if (checked)
                  setSelectedIds((prevIds) =>
                    prevIds.filter((id) => id !== item.id),
                  );
                else setSelectedIds((prevIds) => [...prevIds, item.id]);
              }}
            />
          );
        })}
      </div>
    );
  };

  const getAccordionTitle = (title, selectedItems) => {
    return (
      <div className="display-flex flex-row flex-justify padding-right-2">
        <div>{title} line items</div>
        <CounterTag count={selectedItems.length} />
      </div>
    );
  };

  const showLineItemsGroup = (title, lineItems, selectedIds, setFn) => {
    const [expanded, setExpanded] = useState(false);
    if (!lineItems.length) return null;
    return (
      <AccordionItem
        id={title}
        title={getAccordionTitle(title, selectedIds)}
        expanded={expanded}
        content={showLineItems(title, lineItems, selectedIds, setFn)}
        handleToggle={() => setExpanded(!expanded)}
      />
    );
  };

  const loading = loadingSIs || loadingBidLines || loadingContractLines;
  const totalSelected = selectedContLineIds.length + selectedBidLineIds.length;

  return (
    <div className="afp-modal-wrapper edit-conflicts-modal">
      <div className="afp-modal-overlay">
        <Modal
          id="copy-line-item-modal"
          variant="large"
          title={<h1>Import previous year Line Items</h1>}
          onClose={onClose}
          actions={
            <div>
              <Button
                data-testid="close-copy-line-item-modal-btn"
                variant="unstyled"
                onClick={onClose}
                label="Cancel"
              />
              <CanIChangeBidData>
                <Button
                  data-testid="copy-line-item-modal-append-btn"
                  variant="primary"
                  onClick={() => onSubmit(COPY_TYPE.append)}
                  label="Append and import"
                />
                <Button
                  data-testid="copy-line-item-modal-overwrite-btn"
                  variant="primary"
                  onClick={() => onSubmit(COPY_TYPE.overwrite)}
                  label="Overwrite and import"
                />
              </CanIChangeBidData>
            </div>
          }
        >
          {loading && <OverlaySpinner />}
          {importing && <OverlaySpinner message={importingMessage} />}
          <div className="display-flex flex-row flex-justify flex-align-end">
            <div className="margin-top-2 text-bold text-primary">
              SELECT LINE ITEMS
            </div>
            <div style={{ fontSize: '1.5rem' }}>
              <b>{totalSelected}</b>{' '}
              <span className="text-base">
                Total item{totalSelected > 1 ? 's' : ''}
              </span>
            </div>
          </div>

          {!loading && !contLines.length && !bidLines.length && (
            <div>
              No previous contract lines or bid lines for the Standard Item
            </div>
          )}
          {showLineItemsGroup(
            'Contract',
            contLines,
            selectedContLineIds,
            setSelectedContLineIds,
          )}
          {showLineItemsGroup(
            'Bid',
            bidLines,
            selectedBidLineIds,
            setSelectedBidLineIds,
          )}

          <div className="margin-top-6">
            <span className="text-bold">Append</span> will add the copied data
            to the existing data.
          </div>
          <div className="margin-bottom-neg-2">
            <span className="text-bold">Overwrite</span> will replace the
            existing data.
          </div>
        </Modal>
      </div>
    </div>
  );
};

ImportStandardItemModal.propTypes = {
  standardItem: PropTypes.shape({
    standardItemNumber: PropTypes.string,
    vendorId: PropTypes.string,
    bidId: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ImportStandardItemModal;
