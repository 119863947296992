/* eslint-disable react/prop-types */
import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { gql } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';

import {
  makesFetchOptionsPromise,
  modelsFetchOptionsPromise,
} from './api-helpers';

const SIMakeModelContext = createContext();

export const CREATE_SOP_CONTRACT_LINE = gql`
  mutation createNewContractLine(
    $createNewContractLineInput: CreateNewContractLineInput!
  ) {
    createNewContractLine(
      createNewContractLineInput: $createNewContractLineInput
    ) {
      id
      scheduleLine
    }
  }
`;

export const NON_STANDARD_LINE_TYPES = {
  MANUFACTURER_MODEL_NUMBER: 0,
  MAKE_MODEL_YEAR: 1,
};

export function SIMakeModelPanellProvider({
  allowCustom = true,
  initialState,
  children,
  showChassis: defaultShowChassis,
  requiresChassis: defaultRequiresChassis,
}) {
  const [showChassis, setShowChassis] = useState(defaultShowChassis);
  const [requiresChassis, setRequiresChassis] = useState(
    defaultRequiresChassis,
  );
  const [selectedStandardItem, setSelectedStandardItem] = useState(
    initialState?.standardItem,
  );
  useEffect(() => {
    if (defaultShowChassis === undefined && selectedStandardItem) {
      setRequiresChassis(
        selectedStandardItem?.tags?.value?.includes?.('REQ_CHASSIS_MOD'),
      );
    }
    if (defaultShowChassis === undefined) {
      setShowChassis(
        selectedStandardItem?.tags?.value?.includes?.('REQ_CHASSIS_MOD'),
      );
    }
  }, [showChassis, selectedStandardItem]);

  const { getToken } = useCurrentUser();
  const fetchMakes = useCallback(
    async (input, signal) => {
      const token = await getToken();
      return makesFetchOptionsPromise(input, token, signal);
    },
    [getToken],
  );
  const fetchModels = useCallback(
    async (input, makeCode, signal) => {
      const token = await getToken();
      return modelsFetchOptionsPromise(input, makeCode, token, signal);
    },
    [getToken],
  );

  const [modelYear, setModelYear] = useState(initialState?.modelYear);
  const [makeModelApprovalStatus, setMakeModelApprovalStatus] = useState();
  const [modTag, setModTag] = useState(initialState?.modTag ?? '');

  const bodyMakeInitialState = initialState?.bodyMake
    ? {
        label: initialState?.bodyMake?.makeName,
        value: initialState?.bodyMake?.makeCode,
      }
    : undefined;
  const [bodyMake, setBodyMake] = useState(bodyMakeInitialState);
  const [bodyMakeInputValue, setBodyMakeInputValue] = useState(
    bodyMakeInitialState?.label || '',
  );
  const [bodyMakeOptions, setBodyMakeOptions] = useState();

  const bodyModelInitialState = initialState?.bodyModel
    ? {
        label: initialState?.bodyModel?.modelName,
        value: initialState?.bodyModel?.modelCode,
      }
    : undefined;
  const [bodyModel, setBodyModel] = useState(bodyModelInitialState);
  const [bodyModelInputValue, setBodyModelInputValue] = useState(
    bodyModelInitialState?.label || '',
  );
  const [bodyModelOptions, setBodyModelOptions] = useState();

  const chassisMakeInitialState = initialState?.chassisMake
    ? {
        label: initialState?.chassisMake?.makeName,
        value: initialState?.chassisMake?.makeCode,
      }
    : undefined;
  const [chassisMake, setChassisMake] = useState(chassisMakeInitialState);
  const [chassisMakeInputValue, setChassisMakeInputValue] = useState(
    chassisMakeInitialState?.label || '',
  );
  const [chassisMakeOptions, setChassisMakeOptions] = useState();

  const chassisModelInitialState = initialState?.chassisModel
    ? {
        label: initialState?.chassisModel?.modelName,
        value: initialState?.chassisModel?.modelCode,
      }
    : undefined;
  const [chassisModel, setChassisModel] = useState(chassisModelInitialState);
  const [chassisModelInputValue, setChassisModelInputValue] = useState(
    chassisModelInitialState?.label || '',
  );
  const [chassisModelOptions, setChassisModelOptions] = useState();

  const [dirtyFields, setDirtyFields] = useState([]);
  const getErrors = useCallback(() => {
    const requiredMsg = 'This is a required field';
    const errors = [];

    if (!selectedStandardItem || !selectedStandardItem?.id) {
      errors.push({
        field: 'standardItem',
        message: requiredMsg,
      });
    }

    if (!bodyMake) {
      if (allowCustom) {
        if (!bodyMakeInputValue) {
          errors.push({
            field: 'bodyMake',
            message: requiredMsg,
          });
        }
      } else {
        errors.push({
          field: 'bodyMake',
          message: requiredMsg,
        });
      }
    }

    if (!bodyModel) {
      if (allowCustom) {
        if (!bodyModelInputValue) {
          errors.push({
            field: 'bodyModel',
            message: requiredMsg,
          });
        }
      } else {
        errors.push({
          field: 'bodyModel',
          message: requiredMsg,
        });
      }
    }

    if (showChassis) {
      if (requiresChassis && !chassisMake) {
        if (allowCustom) {
          if (!chassisMakeInputValue) {
            errors.push({
              field: 'chassisMake',
              message: requiredMsg,
            });
          }
        } else {
          errors.push({
            field: 'chassisMake',
            message: requiredMsg,
          });
        }
      }

      if (requiresChassis && !chassisModel) {
        if (allowCustom) {
          if (!chassisModelInputValue) {
            errors.push({
              field: 'chassisModel',
              message: requiredMsg,
            });
          }
        } else {
          errors.push({
            field: 'chassisModel',
            message: requiredMsg,
          });
        }
      }
    }

    if (!modelYear) {
      errors.push({
        field: 'modelYear',
        message: requiredMsg,
      });
    }

    // if (!modTag) {
    //   errors.push({
    //     field: 'modTag',
    //     message: requiredMsg,
    //   });
    // }

    return errors;
  }, [
    selectedStandardItem,
    bodyMake,
    bodyMakeInputValue,
    bodyModel,
    bodyModelInputValue,
    chassisMake,
    chassisMakeInputValue,
    chassisModel,
    chassisModelInputValue,
    modelYear,
    modTag,
    requiresChassis,
  ]);

  const value = {
    selectedStandardItem,
    setSelectedStandardItem,
    showChassis,
    requiresChassis,
    allowCustom,

    bodyMake,
    setBodyMake,
    bodyMakeInputValue,
    setBodyMakeInputValue,
    bodyMakeOptions,
    setBodyMakeOptions,

    bodyModel,
    setBodyModel,
    bodyModelInputValue,
    setBodyModelInputValue,
    bodyModelOptions,
    setBodyModelOptions,

    chassisMake,
    setChassisMake,
    chassisMakeInputValue,
    setChassisMakeInputValue,
    chassisMakeOptions,
    setChassisMakeOptions,

    chassisModel,
    setChassisModel,
    chassisModelInputValue,
    setChassisModelInputValue,
    chassisModelOptions,
    setChassisModelOptions,

    modelYear,
    setModelYear,

    makeModelApprovalStatus,
    setMakeModelApprovalStatus,

    modTag,
    setModTag,

    fetchMakes,
    fetchModels,

    getErrors,
    dirtyFields,
    setDirtyFields,
  };
  return (
    <SIMakeModelContext.Provider value={value}>
      {children}
    </SIMakeModelContext.Provider>
  );
}

export function useSIMakeModelProvider() {
  const context = useContext(SIMakeModelContext);
  if (!context) {
    throw new Error(
      'useSIMakeModelProvider must be used within a SIMakeModelProvider',
    );
  }
  return context;
}
