import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  Button,
  ButtonDropdown,
  ButtonDropdownItem,
  Checkbox,
} from '@gsa/afp-component-library';
import OverlaySpinner from '../../../components/overlay-spinner';
import {
  DOWNLOAD_BUTTON,
  REPORT_CONFIG,
} from './components/contract-report-types';
import {
  getVendorOptions,
  getSolicitationOptions,
  getContractOptions,
  getFuelTypeOptions,
  getSinOptionsByFuelType,
  STEP_2_FORM_DEFAULTS_CONTRACT,
  getStep1QueryParams,
  getStep2ContractLinesQueryParams,
  getSinOptionsByContractLines,
  getLineOptionsByContractLines,
} from './components/contract-report-helper';
import {
  GET_CONTRACTS_FOR_REPORT,
  GET_FUEL_AND_SIN_FOR_CONTRACT_REPORT,
  GET_CONTRACT_LINES_FOR_REPORT,
} from './components/contract-report-query';
import SolicitationOrVendorForm from './components/solicitation-or-vendor-form';
import SingleOrMultiSelect from './components/single-or-multi-select';
import MultiSelectForm from './components/multi-select-form';
import ContractPeriodsForm from './components/contract-periods-form';

const SolicitationSelectionForm = ({
  canViewAll,
  formValues,
  setFormValues,
  onPrev,
  onSubmit,
}) => {
  const reportConfig = REPORT_CONFIG[formValues.report.value];

  const [contracts, setContracts] = useState(null);
  const [selectSolVendor, setSelectSolVendor] = useState('Solicitation');
  const [vendorOptions, setVendorOptions] = useState([]);
  const [solicitationOptions, setSolicitationOptions] = useState([]);
  const [contractOptions, setContractOptions] = useState([]);
  const [contractPeriods, setContractPeriods] = useState([]);

  const [lineData, setLineData] = useState([]);
  const [fuelSinData, setFuelSinData] = useState(null);
  const [fuelTypeOptions, setFuelTypeOptions] = useState([]);
  const [sinOptions, setSinOptions] = useState([]);
  const [lineOptions, setLineOptions] = useState([]);

  const { loading: loadingOpts1 } = useQuery(GET_CONTRACTS_FOR_REPORT, {
    fetchPolicy: 'cache-and-network',
    skip: reportConfig.fuelTypeMS,
    variables: getStep1QueryParams(formValues),
    onCompleted: ({ getContractsForReport }) => {
      const data = getContractsForReport.map((contr) => {
        const {
          solicitationPeriodId,
          solicitation: { solicitationPeriods },
        } = contr;
        const period = solicitationPeriods.find(
          (solPer) => +solPer.id === solicitationPeriodId,
        );
        const contractPeriod = period?.periodType === 'M' ? '2' : '1';
        return { ...contr, contractPeriod };
      });
      setContracts(data);
      setVendorOptions(getVendorOptions(data));
      setSolicitationOptions(getSolicitationOptions(data));
    },
  });

  const { loading: loadingOpts2 } = useQuery(
    GET_FUEL_AND_SIN_FOR_CONTRACT_REPORT,
    {
      fetchPolicy: 'cache-and-network',
      skip: !reportConfig.fuelTypeMS,
      variables: getStep1QueryParams(formValues),
      onCompleted: ({ getFuelAndSINForContractReport: data }) => {
        setFuelSinData(data);
        setFuelTypeOptions(getFuelTypeOptions(data));
      },
    },
  );

  const { loading: loadingOpts3 } = useQuery(GET_CONTRACT_LINES_FOR_REPORT, {
    fetchPolicy: 'cache-and-network',
    skip: !reportConfig.sinSS || !formValues.contracts.value.length,
    variables: getStep2ContractLinesQueryParams(formValues),
    onCompleted: ({ getContractLinesForReport: data }) => {
      setLineData(data);
      setSinOptions(getSinOptionsByContractLines(data));
      setLineOptions(getLineOptionsByContractLines(data, []));
    },
  });

  useEffect(() => {
    if (!reportConfig.contractSS && !reportConfig.contractMS) return;
    if (formValues.solicitations.value.length) {
      const solIds = [];
      formValues.solicitations.value.forEach((v) =>
        solIds.push(...JSON.parse(v)),
      );
      setContractOptions(getContractOptions(contracts, solIds, []));
    } else if (formValues.vendors.value.length) {
      setContractOptions(
        getContractOptions(contracts, [], formValues.vendors.value),
      );
    } else {
      setContractOptions([]);
    }
  }, [formValues.solicitations, formValues.vendors, contracts, reportConfig]);

  const onContractsChange = (value, hasError) => {
    setFormValues((prev) => {
      return {
        ...prev,
        ...STEP_2_FORM_DEFAULTS_CONTRACT,
        contracts: {
          value,
          errorMessage: hasError ? 'Contract is required' : '',
        },
      };
    });
    if (value.length) {
      setContractPeriods(
        contracts
          .filter((contr) => contr.contractNumber === value[0])
          .map((contr) => ({
            contractYear: String(contr.contractYear),
            contractPeriod: contr.contractPeriod,
            periodOptions:
              contr.contractPeriod === '1'
                ? [{ label: '1st', value: '1' }]
                : [
                    { label: '1st', value: '0' },
                    { label: '2nd', value: '2' },
                  ],
          })),
      );
    } else {
      setContractPeriods([]);
    }
  };

  const onPeriodsChange = (value) => {
    setFormValues((prev) => {
      const obj = {
        ...prev.contractPeriods,
        ...value,
      };
      if (
        obj.year1 &&
        obj.period1 &&
        obj.year2 &&
        obj.period2 &&
        obj.year1 === obj.year2 &&
        obj.period1 === obj.period2
      )
        obj.errorMessage = 'Please select two different periods';
      return {
        ...prev,
        contractPeriods: obj,
      };
    });
  };

  const onFuelTypeChange = (value, hasError) => {
    setFormValues((prev) => {
      return {
        ...prev,
        sins: { value: [] },
        fuelTypes: {
          value,
          errorMessage: hasError ? 'Fuel type is required' : '',
        },
      };
    });
    setSinOptions(getSinOptionsByFuelType(fuelSinData, value));
  };

  const onSinChange = (value) => {
    setFormValues((prev) => {
      return {
        ...prev,
        sins: { value },
        contractLines: {
          value: prev.contractLines.value.filter((lv) => {
            const sin = lv.split('|')[0];
            return value.includes(sin);
          }),
        },
      };
    });
    // set contract line options
    if (reportConfig.contractLineMS)
      setLineOptions(getLineOptionsByContractLines(lineData, value));
  };

  const onContractLineChange = (value) => {
    setFormValues((prev) => {
      return { ...prev, contractLines: { value } };
    });
  };

  const onEmailReportChange = ({ target: { checked } }) => {
    setFormValues({ ...formValues, isEmailReport: { value: checked } });
  };

  const onClickSubmit = (fileType) => {
    let hasError = false;
    const newFormValues = {};

    if (
      reportConfig.solVendorSS &&
      selectSolVendor === 'Solicitation' &&
      formValues.solicitations.value.length === 0
    ) {
      newFormValues.solicitations = {
        value: [],
        errorMessage: 'Solicitation is required',
      };
      hasError = true;
    }
    if (
      reportConfig.solVendorSS &&
      selectSolVendor === 'Vendor' &&
      formValues.vendors.value.length === 0
    ) {
      newFormValues.vendors = {
        value: [],
        errorMessage: 'Vendor is required',
      };
      hasError = true;
    }
    if (reportConfig.contractSS && formValues.contracts.value.length === 0) {
      newFormValues.contracts = {
        value: [],
        errorMessage: 'Contract is required',
      };
      hasError = true;
    }
    if (reportConfig.constractPeriodDS) {
      if (formValues.contractPeriods.errorMessage) hasError = true;
      else if (
        !formValues.contractPeriods.year1 ||
        !formValues.contractPeriods.period1 ||
        !formValues.contractPeriods.year2 ||
        !formValues.contractPeriods.period2
      ) {
        newFormValues.contractPeriods = {
          ...formValues.contractPeriods,
          errorMessage: 'Contract periods are required',
        };
        hasError = true;
      }
    }
    if (reportConfig.fuelTypeMS && formValues.fuelTypes.value.length === 0) {
      newFormValues.fuelTypes = {
        value: [],
        errorMessage: 'Fuel type is required',
      };
      hasError = true;
    }

    if (hasError) setFormValues({ ...formValues, ...newFormValues });
    else onSubmit(fileType);
  };

  if (
    !reportConfig.fuelTypeMS &&
    contracts &&
    contracts.length === 0 &&
    fuelSinData &&
    fuelSinData.length === 0
  )
    return (
      <div className="grid-col-6 margin-top-4">
        <h3>{reportConfig.label}</h3>
        <div>
          No contracts found. Please change your selection in the previous step
        </div>
        <div className="grid-row margin-top-8">
          <Button
            label="Previous"
            variant="outline"
            type="button"
            data-testid="prev-button"
            onClick={onPrev}
            leftIcon={{ name: 'arrow_back' }}
          />
        </div>
      </div>
    );

  return (
    <div className="grid-col-6 margin-top-4">
      {(loadingOpts1 || loadingOpts2 || loadingOpts3) && <OverlaySpinner />}

      <h3>{reportConfig.label}</h3>

      {(reportConfig.solVendorSS || reportConfig.solVendorMS) && (
        <SolicitationOrVendorForm
          isMultiSelect={!reportConfig.solVendorSS}
          canViewAll={canViewAll}
          formValues={formValues}
          setFormValues={setFormValues}
          selectSolVendor={selectSolVendor}
          setSelectSolVendor={setSelectSolVendor}
          vendorOptions={vendorOptions}
          solicitationOptions={solicitationOptions}
        />
      )}

      {(reportConfig.contractSS || reportConfig.contractMS) && (
        <SingleOrMultiSelect
          label="Select contract"
          field="contracts"
          options={contractOptions}
          formValue={formValues.contracts}
          onFormChange={onContractsChange}
          required={reportConfig.contractSS}
          isMultiSelect={reportConfig.contractMS}
        />
      )}

      {reportConfig.fuelTypeMS && (
        <MultiSelectForm
          label="Select fuel type"
          field="fuelTypes"
          required
          options={fuelTypeOptions}
          formValue={formValues.fuelTypes}
          onFormChange={onFuelTypeChange}
        />
      )}

      {(reportConfig.sinSS || reportConfig.sinMS) && (
        <SingleOrMultiSelect
          label="Select Standard Item"
          field="sins"
          options={sinOptions}
          formValue={formValues.sins}
          onFormChange={onSinChange}
          isMultiSelect={reportConfig.sinMS}
        />
      )}

      {reportConfig.contractLineMS && (
        <MultiSelectForm
          label="Select contract line"
          field="contractLines"
          options={lineOptions}
          formValue={formValues.contractLines}
          onFormChange={onContractLineChange}
        />
      )}

      {reportConfig.constractPeriodDS && (
        <ContractPeriodsForm
          formValue={formValues.contractPeriods}
          contractPeriods={contractPeriods}
          onFormChange={onPeriodsChange}
        />
      )}

      <div className="margin-top-8">
        <Checkbox
          label="Email the report. Please use this option for long-running reports with large data sets. An email will be sent to you with a link to download the report."
          id="email-report-checkbox"
          data-testid="email-report-checkbox"
          name="email-report-checkbox"
          checked={formValues.isEmailReport.value}
          onChange={onEmailReportChange}
        />
      </div>

      <div className="grid-row margin-top-8">
        <div className="grid-col-auto padding-right-1">
          <Button
            label="Previous"
            variant="outline"
            type="button"
            data-testid="prev-button"
            onClick={onPrev}
            leftIcon={{ name: 'arrow_back' }}
          />
        </div>
        <div className="grid-col-auto">
          <ButtonDropdown
            label="Download report"
            variant="primary"
            data-testId="download-reports-button"
          >
            {reportConfig.fileTypes.map((fileType) => (
              <ButtonDropdownItem
                key={fileType}
                label={DOWNLOAD_BUTTON[fileType].label}
                iconName={DOWNLOAD_BUTTON[fileType].icon}
                onClick={() => onClickSubmit(fileType)}
              />
            ))}
          </ButtonDropdown>
        </div>
      </div>
    </div>
  );
};

SolicitationSelectionForm.defaultProps = { canViewAll: false };
SolicitationSelectionForm.propTypes = {
  canViewAll: PropTypes.bool,
  formValues: PropTypes.shape(Object).isRequired,
  setFormValues: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SolicitationSelectionForm;
