import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@gsa/afp-component-library';

const ModificationNumberInput = ({
  value,
  onChange,
  onBlur,
  errorMessage = undefined,
}) => {
  return (
    <div className="grid-container">
      <div>
        This update will be saved as a version. If this is a modification,
        please enter a modification number below.
      </div>
      <div className="grid-row">
        <TextInput
          data-testid="modification-number-input"
          aria-label="Enter a modification number"
          aria-required="true"
          label="Modification number"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          errorMessage={errorMessage}
        />
        <div className="margin-left-2 padding-top-5">(Optional)</div>
      </div>
    </div>
  );
};

ModificationNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
};

ModificationNumberInput.defaultProps = {
  value: '',
  onChange: () => {},
  onBlur: () => {},
  errorMessage: undefined,
};

export default ModificationNumberInput;
