import React from 'react';
import { SelectDropdown } from '@gsa/afp-component-library';
import { FormPropTypes, FormDefaultProps } from './form-helper';

const SingleSelectForm = ({
  label,
  field,
  required,
  disabled,
  options,
  formValue,
  onFormChange,
}) => {
  const fieldName = `${field}-single-select`;

  const onChange = ({ target: { value } }) => {
    onFormChange(value, required && !value);
  };

  return (
    <SelectDropdown
      id={fieldName}
      data-testid={fieldName}
      name={fieldName}
      label={<span className="text-bold">{label}</span>}
      required={required}
      disabled={disabled}
      value={formValue.value}
      options={options}
      errorMessage={formValue.errorMessage}
      onChange={onChange}
    />
  );
};

SingleSelectForm.propTypes = FormPropTypes;
SingleSelectForm.defaultProps = FormDefaultProps;

export default SingleSelectForm;
