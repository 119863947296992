/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import {
  TextInput,
  SelectDropdown,
  Checkbox,
  MultiSelectDropdown,
  RadioButton,
  RequiredFieldIndicator,
  Label,
} from '@gsa/afp-component-library';
import { emSpaceUnicode, emDashUnicode } from '../../../../utilities/constants';
import { getOriginalValue } from './ec-helpers';
import { formatPrice } from './line-helpers';

export { ReadyIcon } from '../../../bid-dashboard/bid-line-details/components/tags';
export { TableExpanderCell } from '../../../bid-dashboard/bid-line-details/components/table-components';
export {
  getHandelToggle,
  getToggleAccordion,
} from '../../../bid-dashboard/bid-line-details/components/accordion-helpers';

export const AccordionTitle = ({ title, isReady }) => {
  return (
    <div className="display-flex flex-row flex-justify">
      <div>{title}</div>
      {!isReady && (
        <div
          className="text-normal text-white padding-x-1 padding-y-2px radius-sm font-sans-xs"
          style={{ background: '#B50909' }}
        >
          INCOMPLETE
        </div>
      )}
    </div>
  );
};

// Input field components
export const TextInputField = ({
  original,
  onUpdate,
  field,
  onUpdateField,
  id,
  label,
  required,
  hideLabel,
  readonly,
  ...restProps
}) => {
  const originalValue = getOriginalValue(original, field);
  const [errorMessage, setErrorMessage] = useState('');
  const [textValue, setTextValue] = useState(originalValue);

  useEffect(() => {
    setTextValue(originalValue);
  }, [originalValue]);

  const onChange = ({ target: { value } }) => {
    if (required) setErrorMessage(value ? '' : `${label} is mandatory`);
    setTextValue(value);
  };
  const onBlur = ({ target: { value } }) => {
    if (required) setErrorMessage('');
    onUpdate(original, onUpdateField || field, value);
  };

  if (readonly)
    return (
      <div data-testid={`${original.id}-${id}`}>
        {!hideLabel && <div>{label}</div>}
        <div>{textValue || emSpaceUnicode}</div>
      </div>
    );

  return (
    <div className={errorMessage ? 'padding-left-2' : ''}>
      <TextInput
        id={`${original.id}-${id}`}
        data-testid={`${original.id}-${id}`}
        type="text"
        label={hideLabel ? '' : label}
        value={textValue}
        errorMessage={errorMessage}
        onChange={onChange}
        onBlur={onBlur}
        {...restProps}
      />
    </div>
  );
};

export const NumberInputField = ({
  original,
  onUpdate,
  field,
  onUpdateField,
  id,
  label,
  hideLabel,
  required,
  validate,
  isInt,
  prefix,
  disabled,
  readonly,
  dataType,
  ...restProps
}) => {
  const originalValue = getOriginalValue(original, field);

  const [errorMessage, setErrorMessage] = useState('');
  const [textValue, setTextValue] = useState(originalValue);

  useEffect(() => {
    setTextValue(originalValue?.toString() || '');
  }, [originalValue]);

  const onChange = ({ target: { value } }) => {
    setTextValue(value);
    if (!value) setErrorMessage(required ? `${label} is mandatory` : '');
    else if (
      validate &&
      validate(isInt ? Math.round(parseFloat(value)) : parseFloat(value)) !==
        true
    ) {
      const err = validate(
        isInt ? Math.round(parseFloat(value)) : parseFloat(value),
      );
      if (typeof err === 'string') setErrorMessage(err);
      else setErrorMessage('Invalid input');
    } else setErrorMessage('');
  };
  const onBlur = ({ target: { value } }) => {
    setErrorMessage('');
    if (!value) onUpdate(original, onUpdateField || field, value);
    else {
      const val = isInt ? Math.round(parseFloat(value)) : parseFloat(value);
      if (
        validate &&
        validate(isInt ? Math.round(parseFloat(value)) : parseFloat(value)) !==
          true
      )
        setTextValue(originalValue);
      else {
        const strVal = val.toString();
        setTextValue(strVal);
        onUpdate(original, onUpdateField || field, strVal);
      }
    }
  };

  if (readonly)
    return (
      <div data-testid={`${original.id}-${id}`}>
        {!hideLabel && <div>{label}</div>}
        <div>
          {(textValue && dataType === 'currency'
            ? `$${formatPrice(textValue)}`
            : textValue) || emSpaceUnicode}
        </div>
      </div>
    );

  return (
    <div
      className={`${prefix && disabled ? 'input-prefix-disabled' : ''} ${
        errorMessage ? 'padding-left-2' : ''
      }`}
    >
      <TextInput
        {...restProps}
        id={`${original.id}-${id}`}
        data-testid={`${original.id}-${id}`}
        type="number"
        label={hideLabel ? '' : label}
        prefix={prefix}
        disabled={disabled}
        required={required}
        value={textValue}
        errorMessage={errorMessage}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export const SelectDropdownField = ({
  original,
  onUpdate,
  options,
  field,
  onUpdateField,
  id,
  label,
  required,
  disabled,
  hideLabel,
  disallowUpdate,
  readonly,
}) => {
  const originalValue = getOriginalValue(original, field);
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    setSelectedValue(originalValue);
  }, [originalValue]);

  const [errorMessage, setErrorMessage] = useState('');
  const onChange = ({ target: { value } }) => {
    if (required) setErrorMessage(value ? '' : `${label} is mandatory`);
    setSelectedValue(value);
    if (!(disallowUpdate && disallowUpdate(value, true)))
      onUpdate(original, onUpdateField || field, value);
  };
  const onBlur = ({ target: { value } }) => {
    setErrorMessage('');
    if (disallowUpdate && disallowUpdate(value))
      setSelectedValue(original[field].newValue);
  };

  if (readonly) {
    return (
      <div>
        <div>{hideLabel ? '' : label}</div>
        <div>
          {selectedValue
            ? options.find((o) => o.value === selectedValue)?.label ||
              emSpaceUnicode
            : emSpaceUnicode}
        </div>
      </div>
    );
  }

  const classes = classNames({
    'padding-left-2': errorMessage,
  });

  return (
    <div style={{ display: 'inline-block' }} className={classes}>
      <SelectDropdown
        name={`${original.id}-${id}`}
        data-testid={`${original.id}-${id}`}
        label={hideLabel ? '' : label}
        required={required}
        value={selectedValue}
        options={options}
        errorMessage={errorMessage}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
    </div>
  );
};

export const MultiSelectDropdownField = ({
  original,
  onUpdate,
  options,
  field,
  onUpdateField,
  id,
  label,
  required,
  hideLabel,
  readonly,
}) => {
  const originalValue = getOriginalValue(original, field);

  const [errorMessage, setErrorMessage] = useState('');
  const [selectedValues, setSelectedValues] = useState(originalValue);

  useEffect(() => {
    setSelectedValues(originalValue);
  }, [originalValue]);

  const onChange = (value) => {
    if (required)
      setErrorMessage(
        selectedValues.length && !value.length ? `${label} is mandatory` : '',
      );
    setSelectedValues(value);
    onUpdate(original, onUpdateField || field, value);
  };
  const onBlur = () => setErrorMessage('');

  if (readonly) {
    return (
      <div>
        <div>{hideLabel ? '' : label}</div>
        <div>
          {selectedValues?.length
            ? selectedValues
                .map((val) => options.find((o) => o.value === val)?.label)
                .filter((val) => val)
                .map((val) => <div>{val}</div>)
            : emSpaceUnicode}
        </div>
      </div>
    );
  }

  const multiSelectWrapperClasses = classNames({
    'border-05 border-secondary-dark': errorMessage,
  });

  return (
    <div
      data-testid={`${original.id}-${id}`}
      onBlur={!readonly ? onBlur : undefined}
      className={
        errorMessage
          ? 'border-left-05 border-secondary-dark padding-left-2 margin-left-neg-2'
          : ''
      }
    >
      {!hideLabel && (
        <div className={errorMessage ? 'text-bold' : ''}>
          {label} {required && <RequiredFieldIndicator />}
        </div>
      )}
      {errorMessage && (
        <div className="text-bold text-secondary-dark">{errorMessage}</div>
      )}

      <div className={multiSelectWrapperClasses}>
        <MultiSelectDropdown
          id={id}
          name={`${original.id}-${id}`}
          data-testid={`${original.id}-${id}`}
          selectedValues={selectedValues}
          options={options}
          onChange={!readonly ? onChange : undefined}
          readonly={readonly}
        />
      </div>
    </div>
  );
};

export const CheckboxField = ({
  original,
  onUpdate,
  field,
  onUpdateField,
  id,
  label,
  readonly,
}) => {
  const originalValue = getOriginalValue(original, field);

  const onChange = ({ target: { checked } }) => {
    onUpdate(original, onUpdateField || field, checked);
  };

  return (
    <Checkbox
      id={`${original.id}-${id}`}
      data-testid={`${original.id}-${id}`}
      label={label}
      checked={originalValue}
      onChange={onChange}
      disabled={readonly}
    />
  );
};

export const RadioGroupField = ({
  original,
  onUpdate,
  options,
  field,
  onUpdateField,
  id,
  label,
  hideLabel,
  disabled = false,
  readonly,
}) => {
  const originalValue = getOriginalValue(original, field);

  const onChange = ({ target: { value } }) => {
    onUpdate(original, onUpdateField || field, value);
  };

  if (readonly) {
    return (
      <div>
        <div>{hideLabel ? '' : label}</div>
        <div>{originalValue}</div>
      </div>
    );
  }

  return (
    <div>
      {!hideLabel && <div>{label}</div>}
      {options.map((opt) => (
        <RadioButton
          key={`${original.id}-${id}-${opt.value}`}
          name={`${original.id}-${id}`}
          id={`${original.id}-${id}-${opt.value}`}
          data-testid={`${original.id}-${id}-${opt.value}`}
          label={opt.label}
          value={opt.value}
          defaultChecked={opt.value === originalValue}
          onChange={onChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

export const TextareaInputField = ({
  original,
  onUpdate,
  field,
  onUpdateField,
  id,
  label,
  required,
  hideLabel,
  readonly,
  ...restProps
}) => {
  const originalValue = getOriginalValue(original, field);
  const [errorMessage, setErrorMessage] = useState('');
  const [textValue, setTextValue] = useState(originalValue);

  useEffect(() => {
    setTextValue(originalValue);
  }, [originalValue]);

  const onChange = ({ target: { value } }) => {
    if (required) setErrorMessage(value ? '' : `${label} is mandatory`);
    setTextValue(value);
  };
  const onBlur = ({ target: { value } }) => {
    if (required) setErrorMessage('');
    onUpdate(original, onUpdateField || field, value);
  };

  if (readonly) {
    return (
      <div>
        <div>{hideLabel ? '' : label}</div>
        <div>{originalValue || emDashUnicode}</div>
      </div>
    );
  }

  return (
    <TextInput
      {...restProps}
      id={`${original.id}-${id}`}
      data-testid={`${original.id}-${id}`}
      type="textarea"
      label={hideLabel ? '' : label}
      value={textValue}
      errorMessage={errorMessage}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};

/**
 * A wrapper component that renders the children as read-onl
 * if the readonly or disabled prop is set
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} props.children - The child elements to be wrapped.
 * @param {string} props.dataType - The type of the value to format (e.g., 'date').
 * @param {string} props.wrapperType - The type of wrapper to use (e.g., 'grid').
 *
 * @returns {React.ReactNode} The wrapped children with additional formatting.
 */
export const ReadOnlyWrapper = ({ children, dataType, wrapperType }) => {
  let extractedProps = {};
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      extractedProps = { extractedProps, ...child.props };
    }
  });

  const {
    label,
    defaultValue,
    value,
    disabled,
    readonly,
    readOnly,
    help,
  } = extractedProps;

  const isReadOnlyOrDisabled = readonly || readOnly || disabled;

  if (!isReadOnlyOrDisabled) {
    return children;
  }

  const setValue = value || defaultValue;

  const formattedValue = (() => {
    switch (dataType) {
      case 'date':
        return (
          (moment(setValue).isValid() && moment(setValue).format('LL')) ||
          undefined
        );
      case 'currency':
        return setValue ? `$${formatPrice(setValue)}` : '$ -';
      default:
        return setValue;
    }
  })();

  const returnValue = formattedValue || emDashUnicode;

  switch (wrapperType) {
    case 'grid':
      return (
        <div className="grid-col">
          {label && <Label>{label}</Label>}
          {help && <span>{help}</span>}
          <div className="padding-top-1">{returnValue}</div>
        </div>
      );
    default:
      return (
        <div>
          {label && <Label>{label}</Label>}
          {help && <span>{help}</span>}
          <div>{returnValue}</div>
        </div>
      );
  }
};
