import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Button, DatePicker } from '@gsa/afp-component-library';
import classNames from 'classnames';
import { getFilterOptions } from './line-history-helpers';

export const DEFAULT_FILTERS = {
  contractVersion: '',
  modTag: '',
  changedBy: '',
  changedAtFrom: '',
  changedAtTo: '',
  tab: '',
  equipmentCode: '',
  fieldName: '',
};

const LineHistoryFilters = ({
  allVersions,
  filters,
  onFilterChange,
  resetFilters,
}) => {
  const [isHidden, setIsHidden] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const getSingleSelect = (field, label) => {
    const options = getFilterOptions(allVersions, field);
    return (
      <div className="grid-col contract-line-listing-search">
        <SelectDropdown
          data-testid={`${field}-filter`}
          label={label}
          value={filters[field]}
          options={options}
          onChange={({ target: { value } }) => onFilterChange(field, value)}
        />
      </div>
    );
  };

  const getDatePicker = (field, label) => {
    return (
      <div className="grid-col contract-line-listing-search">
        <DatePicker
          data-testid={`${field}-filter`}
          aria-label={label}
          label={label}
          onChange={(value) => onFilterChange(field, value)}
          defaultValue={filters[field]}
        />
      </div>
    );
  };
  const getEmptyDatePicker = (label) => {
    return (
      <div className="grid-col contract-line-listing-search">
        <DatePicker label={label} />
      </div>
    );
  };

  const onReset = () => {
    resetFilters();
    setIsReset(true);
    setTimeout(() => setIsReset(false), 100);
  };

  return (
    <div className="contract-line-listing-search-block padding-2 bg-primary-lighter">
      <div className="display-flex flex-row flex-justify">
        <div className="contract-line-listing-search-header">Filters</div>
        <Button
          label={isHidden ? 'Show filters' : 'Hide filters'}
          variant="outline"
          size="small"
          onClick={() => {
            setIsHidden(!isHidden);
          }}
          leftIcon={{ name: 'filter_alt' }}
        />
      </div>
      <div
        className={classNames('contract-line-listing-search-content', {
          hidden: isHidden,
        })}
        aria-hidden={isHidden}
      >
        <div className="contract-line-listing-search-description display-flex flex-row flex-justify">
          <div>Apply at least one filter to see your results.</div>
          <div className="contract-line-listing-search-reset">
            <Button
              variant="unstyled"
              label="Reset all filters"
              onClick={onReset}
              leftIcon={{ name: 'close' }}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          {getSingleSelect('contractVersion', 'Contract version')}
          {getSingleSelect('modTag', 'Modification number')}
          {getSingleSelect('changedBy', 'Changed by')}
          {!isReset && getDatePicker('changedAtFrom', 'Changed date from')}
          {isReset && getEmptyDatePicker('Changed date from')}
          {!isReset && getDatePicker('changedAtTo', 'Changed date to')}
          {isReset && getEmptyDatePicker('Changed date to')}
        </div>
        <div className="grid-row grid-gap">
          {getSingleSelect('tab', 'Change type')}
          {getSingleSelect('equipmentCode', 'Equipment/color code')}
          {getSingleSelect('fieldName', 'Field name')}
          <div className="grid-col" />
          <div className="grid-col" />
        </div>
      </div>
    </div>
  );
};

LineHistoryFilters.propTypes = {
  allVersions: PropTypes.arrayOf(Object).isRequired,
  filters: PropTypes.shape(Object).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
};

export default LineHistoryFilters;
