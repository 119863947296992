export const hasContractAwardedForNewSolicitationVersion = (aSolicitation) => {
  const { contractYear, contracts } = aSolicitation || {};
  return !!contracts?.some?.(
    (aContract) => aContract.contractYear > contractYear,
  );
};

export const hasContractAwardedForSolicitation = (aSolicitation) => {
  const { contractYear, contracts } = aSolicitation || {};
  return !!contracts?.some?.(
    // if there is renewalYear consider using it, if not use contractYear
    (aContract) => {
      if (aContract?.renewalYear) return aContract.renewalYear === contractYear;
      return aContract.contractYear === contractYear;
    },
  );
};
