import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Spinner, PageTitle, Link, NotFound } from '@gsa/afp-component-library';
import { emDashUnicode } from '../../../utilities/constants';
import { GET_CONTRACT_HEADER_BY_ID } from '../contract-header/contract-header.gql';
import ContractDetails from '../components/contract-details';
import TableWrapper from '../components/table-wrapper';
import { Columns, RowSubComponent } from './contract-header-history-table-def';
import ContractHeaderHistoryFilters, {
  DEFAULT_FILTERS,
} from './contract-header-history-filters';
import {
  GET_CONTRACT_HEADER_HISTORY,
  filterAndSortVersions,
  processHeaderVersions,
} from './contract-header-history-helpers';
import './contract-header-history.scss';
import { ContractContext } from '../contract-provider';
import { ContractHeaderHistoryBreadcrumbs } from './contract-header-history-breadcrumbs';
import { ContractHeaderHistoryActions } from './contract-header-history-actions';

const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50];
const DEFAULT_ITEMS_PER_PAGE = ITEMS_PER_PAGE_OPTIONS[0];

const ContractHeaderHistory = () => {
  const { contractHeaderId } = useParams();
  const [paginationState, setPaginationState] = useState({
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    currentPage: 1,
  });
  const { setMaxVersionNumber } = useContext(ContractContext);
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [order, setOrder] = useState(['versionNumber', 'DESC']);

  const [contractHeaderVersions, setContractHeaderVersions] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [displayVersions, setDisplayVersions] = useState([]);

  const { data: dataHeader, loading: loadingHeader } = useQuery(
    GET_CONTRACT_HEADER_BY_ID,
    {
      variables: { contractHeaderId },
      skip: !contractHeaderId,
    },
  );

  const contractHeader = dataHeader?.getContractHeaderById;
  const contract = contractHeader || {};
  const vendor = contractHeader?.vendor || {};
  const vendorId = contractHeader?.vendor?.id || '';

  const { loading: loadingVersions } = useQuery(GET_CONTRACT_HEADER_HISTORY, {
    fetchPolicy: 'network-only',
    variables: { contractHeaderId },
    onCompleted: ({ getContractHeaderHistory }) => {
      const versions = processHeaderVersions(getContractHeaderHistory);
      if (versions?.length) {
        setMaxVersionNumber(Math.max(...versions.map((v) => v.versionNumber)));
      }
      setContractHeaderVersions(versions);
      setFilteredVersions(versions);
      setDisplayVersions(versions.slice(0, DEFAULT_ITEMS_PER_PAGE));
    },
  });

  const onPageChange = (newPage, itemsPerPage) => {
    const offset = (newPage - 1) * itemsPerPage;
    setPaginationState((prev) => ({
      ...prev,
      currentPage: newPage,
      offset,
      limit: itemsPerPage,
    }));
    setDisplayVersions(filteredVersions.slice(offset, offset + itemsPerPage));
  };

  const onSort = (orderBy) => {
    const [s1, s2] = orderBy.split(' ');
    const newOrder = [s1.split('`')[1], s2.toUpperCase()];
    setOrder(newOrder);
    const filtered = filterAndSortVersions(filteredVersions, {}, newOrder);
    setFilteredVersions(filtered);
    const { offset, limit } = paginationState;
    setDisplayVersions(filtered.slice(offset, offset + limit));
  };

  const onFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [field]: value };
      const filtered = filterAndSortVersions(
        contractHeaderVersions,
        newFilters,
        order,
      );
      setFilteredVersions(filtered);
      let { offset } = paginationState;
      if (filtered.length <= offset) {
        offset = 0;
        setPaginationState((prev) => ({ ...prev, offset, currentPage: 1 }));
      }
      setDisplayVersions(
        filtered.slice(offset, offset + paginationState.limit),
      );
      return newFilters;
    });
  };
  const resetFilters = () => {
    const filtered = filterAndSortVersions(
      contractHeaderVersions,
      DEFAULT_FILTERS,
      order,
    );
    setFilteredVersions(filtered);
    const { offset, limit } = paginationState;
    setDisplayVersions(filtered.slice(offset, offset + limit));
    setFilters(DEFAULT_FILTERS);
  };

  if (loadingHeader) {
    return (
      <>
        <ContractHeaderHistoryBreadcrumbs />
        <Spinner />
      </>
    );
  }

  if (!contractHeader) {
    return (
      <>
        <ContractHeaderHistoryBreadcrumbs />
        <NotFound />
      </>
    );
  }

  return (
    <div id="contract-history">
      <ContractHeaderHistoryBreadcrumbs />
      {(loadingHeader || loadingVersions) && (
        <Spinner data-testid="contract-history-spinner" />
      )}
      {!loadingHeader && !loadingVersions && (
        <>
          <div className="grid-row grid-gap margin-bottom-2">
            <div className="grid-col-10">
              <PageTitle
                title={
                  <>
                    Contract uPIID:{' '}
                    {contractHeader.contractUpiid ||
                      contractHeader.solicitation?.solicitationNumber ||
                      emDashUnicode}
                  </>
                }
              />
              <div>
                <span>Contractor: </span>
                <Link
                  href={`${window.AFP_CONFIG.appURLs.home}/vendor/details/${vendorId}`}
                >
                  {vendor.vendorName}
                </Link>
              </div>
            </div>
            <div className="grid-col-2 display-flex flex-align-end flex-justify-end">
              <ContractHeaderHistoryActions />
            </div>
          </div>

          <ContractDetails contract={contract} />

          <ContractHeaderHistoryFilters
            allVersions={contractHeaderVersions}
            filters={filters}
            onFilterChange={onFilterChange}
            resetFilters={resetFilters}
          />

          <TableWrapper
            tableProps={{
              data: displayVersions,
              columns: Columns,
              defaultSort: 'versionNumber DESC',
              expandable: true,
              onSort,
              renderRowSubComponent: RowSubComponent,
            }}
            paginationProps={{
              itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
              onPageChange,
              variant: 'advanced',
              itemsPerPage: paginationState.limit,
              currentPage: paginationState.currentPage,
              itemsCount: filteredVersions.length ?? 0,
            }}
            emptyStateText="No contract history results found."
          />
        </>
      )}
    </div>
  );
};

export default ContractHeaderHistory;
