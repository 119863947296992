import React from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../widgets/breadcrumbs';

export const CONTRACT_BREADCRUMBS = {
  HOME: {
    label: 'Home',
    location: `${window.AFP_CONFIG.appURLs?.home}/home`,
  },
  CONTRACT_SEARCH: {
    label: 'Contracts',
    getLocation: () => `/catalog/contract`,
  },
  CONTRACT_HEADER: {
    label: 'Contract header',
    getLocation: ({ contractHeaderId }) =>
      `/catalog/contract/contract-header/${contractHeaderId}`,
  },
  CONTRACT_HISTORY: {
    label: 'Contract history',
    getLocation: ({ contractHeaderId }) =>
      `/catalog/contract/contract-header/${contractHeaderId}/history`,
  },
  CONTRACT_HISTORY_VERSION: {
    label: 'Contract history version',
    getLocation: ({ contractHeaderId, versionNumber }) =>
      `/catalog/contract/contract-header/${contractHeaderId}/history/${versionNumber}`,
  },

  // Contract line listing
  CONTRACT_LINE_LISTING: {
    label: 'Contract line listing',
    getLocation: ({ contractHeaderId }) =>
      `/catalog/contract/contract-header/${contractHeaderId}/lines`,
  },
  CONTRACT_LINE: {
    label: 'Line item',
    getLocation: ({ contractHeaderId, contractLineId }) =>
      `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}`,
  },
  CONTRACT_LINE_HISTORY: {
    label: 'Contract line history',
    getLocation: ({ contractHeaderId, contractLineId }) =>
      `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history`,
  },
  CONTRACT_LINE_HISTORY_VERSION: {
    label: 'Version',
    getLocation: ({ contractHeaderId, contractLineId, versionNumber }) =>
      `/catalog/contract/${contractHeaderId}/contract-line-template/${contractLineId}/history/${versionNumber}`,
  },
};

export const ContractBreadcrumbs = ({
  breadcrumbs = [],
  current = {},
  contractHeaderId,
  contractLineId,
  versionNumber,
}) => {
  const paths = breadcrumbs.filter(Boolean).map((breadcrumb) => {
    return {
      label: breadcrumb.label,
      location:
        breadcrumb.location ||
        breadcrumb.getLocation({
          contractHeaderId,
          contractLineId,
          versionNumber,
        }),
    };
  });

  return (
    <Breadcrumbs
      path={paths}
      current={current.label || current || 'INVALID CURRENT VALUE'}
    />
  );
};

ContractBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  current: PropTypes.oneOfType([PropTypes.shape(Object), PropTypes.string])
    .isRequired,
  contractHeaderId: PropTypes.string,
  contractLineId: PropTypes.string,
  versionNumber: PropTypes.string,
};

ContractBreadcrumbs.defaultProps = {
  contractHeaderId: undefined,
  contractLineId: undefined,
  versionNumber: undefined,
};
