import moment from 'moment';
import { string2number } from './ec-helpers';
import { isContractSOP } from '../../components/contract-helpers';

// helpers to get line detail tab input for update
export const getLineTabData = (
  tabData,
  lineItem,
  purchaseType,
  makeAvailableForOrdering,
) => {
  const lineTabData = { ...tabData };
  const input = {};

  if (!isContractSOP(purchaseType)) {
    if (makeAvailableForOrdering === 'Yes') {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const currentMinuteInterval = currentMinute < 30 ? 0 : 30;
      lineTabData.orderStartDate = moment()
        .hours(currentHour)
        .minutes(currentMinuteInterval)
        .seconds(0)
        .milliseconds(0)
        .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
      const endDate = moment(lineItem?.contractHeader?.contractEndDate);
      endDate.hours(23).minutes(30).seconds(0).milliseconds(0);
      lineTabData.orderEndDate = endDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
    } else if (makeAvailableForOrdering === 'No') {
      const currentTime = new Date();
      currentTime.setHours(0, 0, 0, 0);
      lineTabData.orderStartDate = currentTime.toISOString().substring(0, 10);
      lineTabData.orderEndDate = currentTime.toISOString().substring(0, 10);
    }
  }

  if (!lineTabData || !Object.keys(lineTabData).length) return undefined;

  const number2string = (num) => {
    if (num == null) return '';
    return String(num);
  };

  if (lineTabData.makeCode || lineTabData.make?.makeName) {
    input.makeName = {
      newValue: lineTabData.make?.makeName,
      oldValue: lineItem.make?.makeName,
    };
    input.makeCode = {
      newValue: lineTabData.make?.makeCode,
      oldValue: lineItem.make?.makeCode,
    };
  }

  if (lineTabData.modelCode || lineTabData.model?.modelName) {
    input.modelName = {
      newValue: lineTabData.model?.modelName,
      oldValue: lineItem.model?.modelName,
    };
    input.modelCode = {
      newValue: lineTabData.model?.modelCode,
      oldValue: lineItem.model?.modelCode,
    };
  }

  if (lineTabData.modelYear) {
    input.modelYear = {
      newValue: String(lineTabData.modelYear),
      oldValue: String(lineItem.modelYear),
    };
  }

  if ('chassisMakeCode' in lineTabData) {
    input.chassisMakeName = {
      newValue: lineTabData.chassisMake?.makeName || null,
      oldValue: lineItem.chassisMake?.makeName,
    };
    input.chassisMakeCode = {
      newValue: lineTabData.chassisMake?.makeCode || null,
      oldValue: lineItem.chassisMake?.makeCode,
    };
  }

  if ('chassisModelCode' in lineTabData) {
    input.chassisModelName = {
      newValue: lineTabData.chassisModel?.modelName || null,
      oldValue: lineItem.chassisModel?.modelName,
    };
    input.chassisModelCode = {
      newValue: lineTabData.chassisModel?.modelCode || null,
      oldValue: lineItem.chassisModel?.modelCode,
    };
  }

  if ('orderStartDate' in lineTabData) {
    input.orderStartDate = {
      newValue: lineTabData.orderStartDate,
      oldValue: lineItem.orderStartDate,
    };
  }

  if ('orderEndDate' in lineTabData) {
    input.orderEndDate = {
      newValue: lineTabData.orderEndDate,
      oldValue: lineItem.orderEndDate,
    };
  }

  if ('additionalInformation' in lineTabData) {
    input.additionalInformation = {
      newValue: lineTabData.additionalInformation,
      oldValue: lineItem.additionalInformation,
    };
  }

  if ('quantity' in lineTabData) {
    input.quantity = {
      newValue: number2string(lineTabData.quantity),
      oldValue: number2string(lineItem.quantity),
    };
  }

  if ('isPurchaseAvailable' in lineTabData) {
    input.isPurchaseAvailable = {
      newValue: lineTabData.isPurchaseAvailable,
      oldValue: String(lineItem.isPurchaseAvailable),
    };
  }

  if ('makeAvailableForOrdering' in lineTabData) {
    input.makeAvailableForOrdering = {
      newValue: lineTabData.makeAvailableForOrdering,
      oldValue: String(lineItem.makeAvailableForOrdering),
    };
  }

  if ('shipmentOrDelivery' in lineTabData) {
    input.shipmentOrDelivery = {
      newValue: lineTabData.shipmentOrDelivery,
      oldValue: lineItem.shipmentOrDelivery,
    };
  }

  if ('deliveryDate' in lineTabData) {
    input.deliveryDate = {
      newValue: lineTabData.deliveryDate,
      oldValue: lineItem.deliveryDate
        ? `${lineItem.deliveryDate.substring(
            5,
            7,
          )}/${lineItem.deliveryDate.substring(
            8,
            10,
          )}/${lineItem.deliveryDate.substring(0, 4)}`
        : '',
    };
  }

  if ('vin' in lineTabData) {
    input.vin = {
      newValue: lineTabData.vin,
      oldValue: lineItem.vin,
    };
  }

  if ('coFirstName' in lineTabData) {
    input.coFirstName = {
      newValue: lineTabData.coFirstName,
      oldValue: lineItem.coFirstName,
    };
  }

  if ('coLastName' in lineTabData) {
    input.coLastName = {
      newValue: lineTabData.coLastName,
      oldValue: lineItem.coLastName,
    };
  }

  if ('coEmail' in lineTabData) {
    input.coEmail = {
      newValue: lineTabData.coEmail,
      oldValue: lineItem.coEmail,
    };
  }

  if ('manufacturerModelNumber' in lineTabData) {
    input.manufacturerModelNumber = {
      newValue: lineTabData.manufacturerModelNumber,
      oldValue: lineItem.manufacturerModelNumber,
    };
  }

  if ('isDirect' in lineTabData) {
    if (!(lineItem.isDirect == null && lineTabData.isDirect === '')) {
      input.isDirect = {
        newValue: String(lineTabData.isDirect),
        oldValue: String(lineItem.isDirect),
      };
    }
  }

  // eslint-disable-next-line
  for (const key of Object.keys(input)) {
    if (input[key].oldValue === input[key].newValue) delete input[key];
  }

  const financialInfo = lineTabData.contractLineFinancialRef || {};
  if (Object.keys(financialInfo).length > 0) {
    input.contractLineFinancialRef = {};
    const originalFinancialInfo = lineItem.contractLineFinancialRef || {};

    if ('unitPrice' in financialInfo) {
      input.contractLineFinancialRef.unitPrice = {
        newValue: number2string(financialInfo.unitPrice ?? ''),
        oldValue: number2string(originalFinancialInfo.unitPrice ?? ''),
      };
    }

    if ('invoicePrice' in financialInfo) {
      input.contractLineFinancialRef.invoicePrice = {
        newValue: number2string(financialInfo.invoicePrice),
        oldValue: number2string(originalFinancialInfo.invoicePrice),
      };
    }

    if ('msrp' in financialInfo) {
      input.contractLineFinancialRef.msrp = {
        newValue: number2string(financialInfo.msrp),
        oldValue: number2string(originalFinancialInfo.msrp),
      };
    }

    if ('asePrice' in financialInfo) {
      input.contractLineFinancialRef.asePrice = {
        newValue: number2string(financialInfo.asePrice),
        oldValue: number2string(originalFinancialInfo.asePrice),
      };
    }

    if ('destinationCharge' in financialInfo) {
      input.contractLineFinancialRef.destinationCharge = {
        newValue: number2string(financialInfo.destinationCharge),
        oldValue: number2string(originalFinancialInfo.destinationCharge),
      };
    }

    // eslint-disable-next-line
    for (const key of Object.keys(input.contractLineFinancialRef)) {
      if (
        input.contractLineFinancialRef[key].oldValue ===
        input.contractLineFinancialRef[key].newValue
      )
        delete input.contractLineFinancialRef[key];
    }
    if (Object.keys(input.contractLineFinancialRef).length === 0)
      delete input.contractLineFinancialRef;
  }

  return Object.keys(input).length ? input : undefined;
};

// helpers to get line detail tab input for update
export const number2bool = (value) => {
  if (value === 1) return true;
  if (value === 0) return false;
  return null;
};
export const string2boolNumber = (value) => {
  return value === 'true' ? 1 : 0;
};
const getChange = (oldValue, newValue) => {
  if (oldValue === newValue) return undefined;
  return { oldValue, newValue };
};

const getMismatchLocations = (locations, excludes, isSop) => {
  return (locations ?? []).filter(
    (loc) =>
      !(excludes ?? []).find(
        (excLoc) =>
          loc.vendorAssemblyPointId === excLoc.vendorAssemblyPointId &&
          loc.vendorInspectionPointId === excLoc.vendorInspectionPointId &&
          (!isSop ||
            loc.percentageOfDomContent === excLoc.percentageOfDomContent),
      ),
  );
};
const mapLocations = (locations, isDeleted) => {
  return locations.map((l) => ({
    ...(isDeleted ? { isDeleted: true } : {}),
    id: l.id,
    vendorAssemblyPointId: l.vendorAssemblyPointId,
    vendorInspectionPointId: l.vendorInspectionPointId,
    percentageOfDomContent: l.percentageOfDomContent,
  }));
};
const getClarificationInputs = (originalClarifications, clarifications) => {
  const clarInputs = [];

  originalClarifications.forEach((c) => {
    const newC = clarifications.find((nc) => nc.id === c.id);
    if (!newC) {
      // delete
      clarInputs.push({
        isDeleted: true,
        id: +c.id,
        clarificationType: c.clarificationType,
        clarification: { oldValue: c.clarification },
      });
    } else if (c.clarification !== newC.clarification) {
      // update
      clarInputs.push({
        id: +c.id,
        clarificationType: c.clarificationType,
        clarification: {
          oldValue: c.clarification,
          newValue: newC.clarification,
        },
      });
    }
  });

  // new entry
  clarifications.forEach((c) => {
    if (c.id.startsWith('local_')) {
      clarInputs.push({
        clarificationType: c.clarificationType,
        clarification: { newValue: c.clarification },
      });
    }
  });

  return clarInputs;
};

export const getLineDetailTabData = (
  contractLine,
  lineDetailTabData,
  isSop,
) => {
  if (!lineDetailTabData) return undefined;

  const input = {};

  if (lineDetailTabData.participant1122Prog !== null) {
    const participant1122Prog = getChange(
      contractLine.participant1122Prog || 0,
      string2boolNumber(lineDetailTabData.participant1122Prog),
    );
    if (participant1122Prog) input.participant1122Prog = participant1122Prog;
  }

  if (lineDetailTabData.shipmentDays !== null) {
    const shipmentDays = getChange(
      contractLine.shipmentDays ?? null,
      string2number(lineDetailTabData.shipmentDays),
    );
    if (shipmentDays) input.shipmentDays = shipmentDays;
  }

  if (lineDetailTabData.shipmentJustification !== null) {
    const shipmentJustification = getChange(
      contractLine.shipmentJustification || null,
      lineDetailTabData.shipmentJustification || null,
    );
    if (shipmentJustification)
      input.shipmentJustification = shipmentJustification;
  }

  if (lineDetailTabData.associatedLocations !== null) {
    const deletedLocs = getMismatchLocations(
      contractLine.associatedLocations,
      lineDetailTabData.associatedLocations,
      isSop,
    );
    const newLocs = getMismatchLocations(
      lineDetailTabData.associatedLocations,
      contractLine.associatedLocations,
      isSop,
    );
    const locChanges = [
      ...mapLocations(deletedLocs, true),
      ...mapLocations(newLocs),
    ];
    if (locChanges.length) input.associatedLocations = locChanges;
  }

  if (lineDetailTabData.clarifications !== null) {
    const clarInputs = getClarificationInputs(
      contractLine.contractLineClarifications,
      lineDetailTabData.clarifications,
    );
    if (clarInputs.length > 0) input.clarifications = clarInputs;
  }

  if (Object.keys(input).length === 0) return undefined;
  return input;
};

// helpers to get colors tab input for update
const getFieldValue = (field, obj) =>
  field.converter ? field.converter(obj[field.name]) : obj[field.name] || '';

const ColorFields = [
  { apiName: 'description', name: 'vendorColorName' },
  {
    apiName: 'gsaColorCodeId',
    name: 'gsaColorCodeId',
    converter: string2number,
  },
  { apiName: 'standardPremiumCode', name: 'standardPremiumCode' },
  {
    apiName: 'price',
    name: 'price',
    converter: string2number,
  },
];
const getColorInput = (prevColor, color) => {
  return ColorFields.reduce((inputObj, field) => {
    const oldValue = getFieldValue(field, prevColor);
    const newValue = getFieldValue(field, color);
    if (oldValue !== newValue)
      return { ...inputObj, [field.apiName]: { oldValue, newValue } };
    return inputObj;
  }, {});
};
const getColorObj = (prevColor, color) => {
  // new or update
  if (color) {
    const input = getColorInput(prevColor ?? {}, color);
    if (Object.keys(input).length === 0) return undefined;
    return { colorCode: color.vendorColorCode, ...input };
  }
  // delete
  if (prevColor && !color) {
    const input = getColorInput(prevColor, {});
    return { colorCode: prevColor.vendorColorCode, isDeleted: true, ...input };
  }
  return undefined;
};

export const getColorsTabData = (prevColors, newColors) => {
  if (!newColors) return undefined; // no change

  const colorsTabData = [];

  // new or update
  newColors.forEach((c) => {
    const prevC = prevColors.find(
      (pc) => pc.vendorColorCode === c.vendorColorCode,
    );
    const colorData = getColorObj(prevC, c);
    if (colorData) colorsTabData.push(colorData);
  });

  // delete
  prevColors.forEach((pc) => {
    if (!newColors.find((nc) => pc.vendorColorCode === nc.vendorColorCode))
      colorsTabData.push(getColorObj(pc, undefined));
  });

  if (!colorsTabData.length) return undefined;
  return colorsTabData;
};

export const exportsForTesting = {
  getChange,
  getMismatchLocations,
  mapLocations,
  getClarificationInputs,
  getFieldValue,
  getColorInput,
  getColorObj,
};

export const formatPrice = (price, style, currency) => {
  return new Intl.NumberFormat('en-US', {
    style,
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  }).format(price);
};
