import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SelectDropdown, Button } from '@gsa/afp-component-library';

import './contract-line-listing-search.scss';
import classNames from 'classnames';
import { emDashUnicode } from '../../../utilities/constants';

const NO_VAL = '';
const EMPTY_OPTION = { label: '-Select-', value: NO_VAL };

export const DEFAULT_FILTERS = {
  scheduleLine: '',
  makeCode: '',
  modelCode: '',
  chassisMakeCode: '',
  chassisModelCode: '',
  modelYear: '',
  manufacturerModelNumber: '',
};

const FILTER_OPTIONS = {
  scheduleLine: [EMPTY_OPTION],
  makeCode: [EMPTY_OPTION],
  modelCode: [EMPTY_OPTION],
  chassisMakeCode: [EMPTY_OPTION],
  chassisModelCode: [EMPTY_OPTION],
  modelYear: [EMPTY_OPTION],
  manufacturerModelNumber: [EMPTY_OPTION],
};

const getFilterOptions = (field, contractLines) => {
  const options = contractLines.map((line) => {
    switch (field) {
      case 'makeCode':
        return {
          label: line.make?.makeName,
          value: line.make?.makeCode,
        };
      case 'modelCode':
        return {
          label: line.model?.modelName,
          value: line.model?.modelCode,
        };
      case 'chassisMakeCode':
        return {
          label: line.chassisMake?.makeName,
          value: line.chassisMake?.makeCode,
        };
      case 'chassisModelCode':
        return {
          label: line.chassisModel?.modelName,
          value: line.chassisModel?.modelCode,
        };
      case 'modelYear':
        return {
          label:
            line.modelYear === 1901 ? emDashUnicode : String(line.modelYear),
          value: line.modelYear,
        };
      default:
        return {
          label: line[field],
          value: line[field],
        };
    }
  });

  const filteredOptions = options.filter((option) => !!option.value);

  const uniqueOptions = [
    ...new Map(filteredOptions.map((item) => [item.value, item])).values(),
  ].sort((a, b) => (a.label < b.label ? -1 : 1));

  return [EMPTY_OPTION, ...uniqueOptions];
};

const ContractLineListingFilter = ({
  contractLines,
  filters,
  setFilters,
  onApplyFilters,
  isSop,
}) => {
  const [filterOptions, setFilterOptions] = useState(FILTER_OPTIONS);
  const [isHidden, setIsHidden] = useState(false);

  // Set filter options to data values
  useEffect(() => {
    const newFilterOptions = {
      ...filterOptions,
      scheduleLine: getFilterOptions('scheduleLine', contractLines),
      makeCode: getFilterOptions('makeCode', contractLines),
      modelCode: getFilterOptions('modelCode', contractLines),
      chassisMakeCode: getFilterOptions('chassisMakeCode', contractLines),
      chassisModelCode: getFilterOptions('chassisModelCode', contractLines),
      ...(isSop
        ? { modelYear: getFilterOptions('modelYear', contractLines) }
        : {
            manufacturerModelNumber: getFilterOptions(
              'manufacturerModelNumber',
              contractLines,
            ),
          }),
    };
    setFilterOptions(newFilterOptions);
  }, [contractLines]);

  const onFilterChange = (key, val) => {
    setFilters({ ...filters, [key]: val });
  };

  const resetFilters = () => {
    setFilters(DEFAULT_FILTERS);
    onApplyFilters(true);
  };

  const getSingleSelect = (field, label) => {
    return (
      <div
        className={
          field === 'manufacturerModelNumber'
            ? 'col-2 contract-line-listing-search'
            : `grid-col contract-line-listing-search`
        }
      >
        <SelectDropdown
          data-testid={`${field}-filter`}
          label={label}
          value={filters[field]}
          options={filterOptions[field]}
          onChange={({ target: { value } }) => onFilterChange(field, value)}
        />
      </div>
    );
  };

  return (
    <div className="contract-line-listing-search-block padding-2 bg-primary-lighter">
      <div className="display-flex flex-row flex-justify">
        <div className="contract-line-listing-search-header">Filters</div>
        <Button
          label={isHidden ? 'Show filters' : 'Hide filters'}
          variant="outline"
          size="small"
          onClick={() => {
            setIsHidden(!isHidden);
          }}
          leftIcon={{ name: 'filter_alt' }}
        />
      </div>
      <div
        className={classNames('contract-line-listing-search-content', {
          hidden: isHidden,
        })}
        aria-hidden={isHidden}
      >
        <div className="contract-line-listing-search-description display-flex flex-row flex-justify">
          <div>Apply at least one filter to see your results.</div>
          <div className="contract-line-listing-search-reset">
            <Button
              variant="unstyled"
              label="Reset all filters"
              onClick={resetFilters}
              leftIcon={{ name: 'close' }}
            />
          </div>
        </div>
        <div className="grid-row grid-gap">
          {getSingleSelect('scheduleLine', 'Contract line')}
          {getSingleSelect('makeCode', 'Body make')}
          {getSingleSelect('modelCode', 'Body model')}
          {getSingleSelect('chassisMakeCode', 'Chassis make')}
          {getSingleSelect('chassisModelCode', 'Chassis model')}
          {isSop
            ? getSingleSelect('modelYear', 'Model year')
            : getSingleSelect(
                'manufacturerModelNumber',
                'Manufacturer model number',
              )}
        </div>
        <div className="contract-line-listing-search-apply display-flex flex-row flex-justify">
          <Button label="Apply filters" onClick={() => onApplyFilters()} />
        </div>
      </div>
    </div>
  );
};

ContractLineListingFilter.propTypes = {
  contractLines: PropTypes.shape(Array).isRequired,
  filters: PropTypes.shape(Object).isRequired,
  setFilters: PropTypes.func.isRequired,
  onApplyFilters: PropTypes.func.isRequired,
  isSop: PropTypes.bool.isRequired,
};

export default ContractLineListingFilter;
