/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  CONTRACT_BREADCRUMBS,
  ContractBreadcrumbs,
} from '../contract-breadcrumbs';

export const LineHistoryBreadcrumbs = ({ scheduleLine }) => {
  const { contractHeaderId, versionNumber, contractLineId } = useParams();

  const scheduleLineLabel = [
    CONTRACT_BREADCRUMBS.CONTRACT_LINE.label,
    scheduleLine,
  ]
    .filter(Boolean)
    .join(' ');
  return (
    <ContractBreadcrumbs
      breadcrumbs={[
        CONTRACT_BREADCRUMBS.HOME,
        CONTRACT_BREADCRUMBS.CONTRACT_SEARCH,
        CONTRACT_BREADCRUMBS.CONTRACT_HEADER,
        CONTRACT_BREADCRUMBS.CONTRACT_LINE_LISTING,
        {
          ...CONTRACT_BREADCRUMBS.CONTRACT_LINE,
          label: scheduleLineLabel,
        },
      ]}
      current={CONTRACT_BREADCRUMBS.CONTRACT_LINE_HISTORY}
      contractHeaderId={contractHeaderId}
      versionNumber={versionNumber}
      contractLineId={contractLineId}
    />
  );
};

LineHistoryBreadcrumbs.propTypes = {
  scheduleLine: PropTypes.string,
};

LineHistoryBreadcrumbs.defaultProps = {
  scheduleLine: undefined,
};
