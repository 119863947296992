import { gql } from '@apollo/client';

export const GET_STANDARD_ITEM_IDS_BY_SIN = gql`
  query GetStandardItemIdsBySIN($sin: String!) {
    getStandardItemIdsBySIN(sin: $sin)
  }
`;

export const GET_BID_LINES_FOR_IMPORT = gql`
  query getBidLinesForImport(
    $vendorId: String!
    $standardItemIds: [Int!]!
    $contractYears: [Int!]!
    $destBidId: Int!
  ) {
    getBidLinesForImport(
      vendorId: $vendorId
      standardItemIds: $standardItemIds
      contractYears: $contractYears
      destBidId: $destBidId
    ) {
      id
      bidId
      bid {
        id
        vendorId
        solicitationId
        soliciation {
          contractYear
        }
      }
      makeCode
      modelCode
      modelYear
      chassisMakeCode
      chassisModelCode
      afpMake {
        makeName
        makeCode
      }
      afpModel {
        modelName
        modelCode
      }
      chassisMake {
        makeName
        makeCode
      }
      chassisModel {
        modelName
        modelCode
      }
      standardItemId
      standardItem {
        id
        standardItemNumber
        tags
      }
    }
  }
`;

export const GET_CONTRACT_LINES_FOR_IMPORT = gql`
  query getContractLinesForImport(
    $vendorId: String!
    $standardItemIds: [Int!]!
    $contractYears: [Int!]!
  ) {
    getContractLinesForImport(
      vendorId: $vendorId
      standardItemIds: $standardItemIds
      contractYears: $contractYears
    ) {
      id
      contractHeader {
        contractYear
      }
      standardItemId
      makeCode
      modelCode
      modelYear
      chassisMakeCode
      chassisModelCode
      make {
        makeName
        makeCode
      }
      model {
        modelName
        modelCode
      }
      chassisMake {
        makeName
        makeCode
      }
      chassisModel {
        modelName
        modelCode
      }
      standardItem {
        id
        standardItemNumber
        tags
      }
    }
  }
`;

export const IMPORT_CONTRACT_BID_LINES = gql`
  mutation importContractBidLines($input: ImportContractBidLinesInput!) {
    importContractBidLines(input: $input)
  }
`;

const matchLines = (line1, line2) => {
  const chassis1Make =
    line1.chassisMakeCode === 1 ? null : line1.chassisMakeCode;
  const chassis2Make =
    line2.chassisMakeCode === 1 ? null : line2.chassisMakeCode;
  const chassis1Model =
    line1.chassisModelCode === '-1' ? null : line1.chassisModelCode;
  const chassis2Model =
    line2.chassisModelCode === '-1' ? null : line2.chassisModelCode;
  return (
    line1.makeCode === line2.makeCode &&
    line1.modelCode === line2.modelCode &&
    chassis1Make === chassis2Make &&
    chassis1Model === chassis2Model
  );
};

const getContractYear = (line) =>
  line.contractHeader !== undefined
    ? line.contractHeader.contractYear
    : line.bid?.soliciation?.contractYear;

const getLineLabel = (line) => {
  const contractYear = getContractYear(line) || '';
  const sin = line.standardItem?.standardItemNumber || '';
  const make = (line.make || line.afpMake)?.makeName || '';
  const model = (line.model || line.afpModel)?.modelName || '';
  const modelYear = line.modelYear || '';
  const makeModel = [make, model, modelYear].filter((a) => a).join('/');
  const chassisMake = line.chassisMake?.makeName || '';
  const chassisModel = line.chassisModel?.modelName || '';
  const chassis =
    chassisMake && chassisModel
      ? `(chassis ${chassisMake}/${chassisModel})`
      : '';
  return `${contractYear} ${sin} ${makeModel} ${chassis}`.trim();
};

const getMapLineDataFn = (fromField, standardItem) => (data) => {
  const destBidLine = standardItem.afpMake.find((line) =>
    matchLines(line, data),
  );
  const chassisMakeCode =
    data.chassisMakeCode === 1 ? null : data.chassisMakeCode;
  const chassisModelCode =
    data.chassisModelCode === '-1' ? null : data.chassisModelCode;
  const destVar = destBidLine
    ? { toBidLineId: +destBidLine.bidLineId }
    : {
        toCreateBidLine: {
          solicitationLineId: +standardItem.solicitationLineId,
          bidId: standardItem.bidId,
          standardItemId: +standardItem.standardItemId,
          standardItemNumber: standardItem.standardItemNumber,
          makeCode: data.makeCode,
          modelCode: data.modelCode,
          modelYear: data.modelYear,
          ...(chassisMakeCode ? { chassisMakeCode: +chassisMakeCode } : {}),
          ...(chassisModelCode ? { chassisModelCode } : {}),
        },
      };
  return {
    id: data.id,
    label: getLineLabel(data),
    variables: { [fromField]: +data.id, ...destVar },
  };
};

const getUniqueLines = (lines) => {
  const uLines = [];
  lines.forEach((line) => {
    const index = uLines.findIndex((ul) => matchLines(ul, line));
    if (index < 0) uLines.push(line);
    else if (getContractYear(line) > getContractYear(uLines[index])) {
      uLines.splice(index, 1, line);
    }
  });
  return uLines;
};

export const processContractLineData = (contractLineData, standardItem) => {
  return getUniqueLines(contractLineData)
    .map(getMapLineDataFn('fromContractLineId', standardItem))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const processBidLineData = (
  bidLineData,
  contractLineData,
  standardItem,
) => {
  return getUniqueLines(bidLineData)
    .filter(
      (bLine) => !contractLineData.some((cLine) => matchLines(bLine, cLine)),
    )
    .map(getMapLineDataFn('fromBidLineId', standardItem))
    .sort((a, b) => (a.label > b.label ? 1 : -1));
};

export const exportForTesting = {
  matchLines,
  getContractYear,
  getLineLabel,
  getUniqueLines,
};
