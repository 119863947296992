import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner, FlexView, NotFound } from '@gsa/afp-component-library';
import { useQuery } from '@apollo/client';
import { emDashUnicode } from '../../../utilities/constants';
import LineHistoryActions from './line-history-actions';
import { LineHistoryBreadcrumbs } from './line-history-breadcrumbs';
import LineHeader from '../line-template/line-header';
import TableWrapper from '../components/table-wrapper';
import { getColumns, LineHistorySubrow } from './line-history-table-defs';
import LineHistoryFilters, { DEFAULT_FILTERS } from './line-history-filters';
import {
  GET_CONTRACT_LINE_HISTORY,
  GET_CONTRACT_LINE_BY_ID,
} from './line-history.gql';
import {
  filterAndSortVersions,
  processLineVersionData,
} from './line-history-helpers';
import './line-history.scss';

const ITEMS_PER_PAGE_OPTIONS = [10, 25, 50];
const DEFAULT_ITEMS_PER_PAGE = ITEMS_PER_PAGE_OPTIONS[0];

const ContractLineHistory = () => {
  const { contractHeaderId, contractLineId } = useParams();

  const [paginationState, setPaginationState] = useState({
    limit: DEFAULT_ITEMS_PER_PAGE,
    offset: 0,
    currentPage: 1,
  });
  const [filters, setFilters] = useState(DEFAULT_FILTERS);
  const [order, setOrder] = useState(['contractLineVersion', 'DESC']);

  const [lineVersions, setLineVersions] = useState([]);
  const [filteredVersions, setFilteredVersions] = useState([]);
  const [displayVersions, setDisplayVersions] = useState([]);

  const { data: lineData, loading: loadingLine } = useQuery(
    GET_CONTRACT_LINE_BY_ID,
    {
      variables: {
        contractLineId: parseFloat(contractLineId),
      },
      skip: !contractLineId,
    },
  );

  const { data: versionsData, loading: loadingHistory, called } = useQuery(
    GET_CONTRACT_LINE_HISTORY,
    {
      variables: { contractLineId: +contractLineId },
      fetchPolicy: 'network-only',
      skip: !contractLineId,
      onCompleted: ({ getContractLineHistory }) => {
        const versions = processLineVersionData(getContractLineHistory);
        setLineVersions(versions);
        setFilteredVersions(versions);
        setDisplayVersions(versions.slice(0, DEFAULT_ITEMS_PER_PAGE));
      },
    },
  );

  const contractLine = lineData?.getContractLineTemplateById;
  const solicitaion = contractLine?.contractHeader?.solicitation;
  const purchaseTypeCode = solicitaion?.purchaseTypeCode;
  const isNonStandard = purchaseTypeCode === 'N';
  const isMas = purchaseTypeCode === 'M';
  const showManufacturerNumber =
    (isNonStandard && contractLine?.manufacturerModelNumber) || isMas;

  const maxContractLineHistoryVersion =
    versionsData?.getContractLineHistory?.reduce((prev, current) => {
      return prev.versionNumber > current.versionNumber ? prev : current;
    }, {}) || {};
  const maxContractLineVersionNumber =
    maxContractLineHistoryVersion.versionNumber;
  const maxContractHeaderHistoryVersionNumber =
    maxContractLineHistoryVersion.contractHeaderVersion?.versionNumber;

  const onPageChange = (newPage, itemsPerPage) => {
    const offset = (newPage - 1) * itemsPerPage;
    setPaginationState((prev) => ({
      ...prev,
      currentPage: newPage,
      offset,
      limit: itemsPerPage,
    }));
    setDisplayVersions(filteredVersions.slice(offset, offset + itemsPerPage));
  };

  const onSort = (orderBy) => {
    const [s1, s2] = orderBy.split(' ');
    const newOrder = [s1.split('`')[1], s2.toUpperCase()];
    setOrder(newOrder);
    const filtered = filterAndSortVersions(filteredVersions, {}, newOrder);
    setFilteredVersions(filtered);
    const { offset, limit } = paginationState;
    setDisplayVersions(filtered.slice(offset, offset + limit));
  };

  const onFilterChange = (field, value) => {
    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters, [field]: value };
      const filtered = filterAndSortVersions(lineVersions, newFilters, order);
      setFilteredVersions(filtered);
      let { offset } = paginationState;
      if (filtered.length <= offset) {
        offset = 0;
        setPaginationState((prev) => ({ ...prev, offset, currentPage: 1 }));
      }
      setDisplayVersions(
        filtered.slice(offset, offset + paginationState.limit),
      );
      return newFilters;
    });
  };

  const resetFilters = () => {
    const filtered = filterAndSortVersions(
      lineVersions,
      DEFAULT_FILTERS,
      order,
    );
    setFilteredVersions(filtered);
    const { offset, limit } = paginationState;
    setDisplayVersions(filtered.slice(offset, offset + limit));
    setFilters(DEFAULT_FILTERS);
  };

  if (loadingLine || loadingHistory) {
    return (
      <div id="line-history">
        <LineHistoryBreadcrumbs scheduleLine="" />
        <Spinner />
      </div>
    );
  }

  if (called && !contractLine) {
    return (
      <div id="line-history">
        <LineHistoryBreadcrumbs scheduleLine="" />
        <NotFound />
      </div>
    );
  }

  if (called && (!versionsData || !lineData)) {
    return (
      <div id="line-history">
        <LineHistoryBreadcrumbs scheduleLine={contractLine?.scheduleLine} />
        <NotFound />
      </div>
    );
  }

  return (
    <div id="line-history">
      <LineHistoryBreadcrumbs scheduleLine={contractLine?.scheduleLine} />

      <FlexView
        vAlignContent="bottom"
        grow
        className="margin-top-2 margin-bottom-2"
      >
        <div className="margin-bottom-2">
          <FlexView vAlignContent="center" className="margin-bottom-1">
            <div className="title-xl">
              Line Item {contractLine?.scheduleLine}
            </div>
          </FlexView>
          <FlexView className="margin-top-2">
            {showManufacturerNumber ? (
              <div className="title-m">
                <span className=" title-m-bold">
                  Manufacturer model number:{' '}
                </span>
                {contractLine?.manufacturerModelNumber || emDashUnicode}
              </div>
            ) : (
              <>
                <div className="margin-right-2 title-m">
                  <span className=" title-m-bold">Body: </span>
                  {contractLine?.make?.makeName || emDashUnicode}{' '}
                  {contractLine?.model?.modelName || emDashUnicode}
                </div>
                {contractLine?.chassisMake && (
                  <div className="title-m">
                    <span className=" title-m-bold">Chassis: </span>
                    {contractLine?.chassisMake?.makeName || emDashUnicode}{' '}
                    {contractLine?.chassisModel?.modelName || emDashUnicode}
                  </div>
                )}
              </>
            )}
          </FlexView>
        </div>
        <FlexView grow> </FlexView>
        <LineHistoryActions />
      </FlexView>

      <LineHeader contractLine={contractLine} />

      <div className="title-m-bold margin-top-4 margin-bottom-2">
        Contract line history
      </div>

      <LineHistoryFilters
        allVersions={lineVersions}
        filters={filters}
        onFilterChange={onFilterChange}
        resetFilters={resetFilters}
      />

      <TableWrapper
        tableProps={{
          data: displayVersions,
          columns: getColumns(
            contractHeaderId,
            contractLineId,
            maxContractHeaderHistoryVersionNumber,
            maxContractLineVersionNumber,
          ),
          defaultSort: 'contractLineVersion DESC',
          expandable: true,
          order,
          onSort,
          renderRowSubComponent: LineHistorySubrow,
        }}
        paginationProps={{
          itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
          onPageChange,
          variant: 'advanced',
          itemsPerPage: paginationState.limit,
          currentPage: paginationState.currentPage,
          itemsCount: filteredVersions.length ?? 0,
        }}
        emptyStateText="No contract line history results found."
      />
    </div>
  );
};

export default ContractLineHistory;
