/* eslint-disable import/prefer-default-export */
import React from 'react';
import {
  CONTRACT_BREADCRUMBS,
  ContractBreadcrumbs,
} from '../contract-breadcrumbs';

export const ContractHeaderBreadcrumbs = () => {
  return (
    <ContractBreadcrumbs
      breadcrumbs={[
        CONTRACT_BREADCRUMBS.HOME,
        CONTRACT_BREADCRUMBS.CONTRACT_SEARCH,
      ]}
      current={CONTRACT_BREADCRUMBS.CONTRACT_HEADER}
    />
  );
};
