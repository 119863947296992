/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  CONTRACT_BREADCRUMBS,
  ContractBreadcrumbs,
} from '../contract-breadcrumbs';

export const ContractHeaderHistoryBreadcrumbs = () => {
  const { contractHeaderId } = useParams();

  return (
    <ContractBreadcrumbs
      breadcrumbs={[
        CONTRACT_BREADCRUMBS.HOME,
        CONTRACT_BREADCRUMBS.CONTRACT_SEARCH,
        CONTRACT_BREADCRUMBS.CONTRACT_HEADER,
      ]}
      current={CONTRACT_BREADCRUMBS.CONTRACT_HISTORY}
      contractHeaderId={contractHeaderId}
    />
  );
};
