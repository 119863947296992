import React from 'react';
import { Button } from '@gsa/afp-component-library';
import exportFile from '../../../../components/file-export/client-side-file-export';

export const getDownloadAlert = (
  reportTitle,
  fileData,
  fileType,
  fileName,
) => ({
  type: 'success',
  message: (
    <div>
      {reportTitle} has been generated successfully.{' '}
      <Button
        variant="unstyled"
        label="Click here to download the report"
        onClick={() => {
          exportFile(Buffer.from(fileData), fileType, fileName);
        }}
      />
    </div>
  ),
});

export const asyncDownloadAlert = {
  type: 'success',
  message:
    'The report is processing and might take time to generate. An email will be sent to you with a link to download the report.',
};
