import React from 'react';
import PropTypes from 'prop-types';
import { uniq } from 'lodash';
import StandardItemSelect from './standard-item-select';
import StandardItemMakeModelPanel from '../make-model/si-make-model-panel';
import { useSIMakeModelProvider } from '../make-model/si-make-model-panel-provider';

const AddSopLine = ({ contractHeader }) => {
  const {
    setSelectedStandardItem,
    getErrors,
    setDirtyFields,
    dirtyFields,
  } = useSIMakeModelProvider();
  const solicitationStandardItems = contractHeader?.solicitation?.programs?.flatMap(
    (program) => {
      const standardItems = program?.solicitationLines?.map((line) => {
        return line?.standardItem;
      });
      return standardItems;
    },
  );
  const options = solicitationStandardItems?.map((s) => ({
    label: `${s.standardItemNumber} - ${s.title}`,
    value: s.id,
  }));
  const errors = getErrors();
  const standardItemError = errors?.find((e) => e.field === 'standardItem')
    ?.message;

  return (
    <div className="margin-top-4">
      <div className="margin-bottom-4">
        <StandardItemSelect
          options={options || []}
          onChange={(evt) => {
            const selectedStandardItemId = evt.target.value;
            const newSelectedStandardItem = solicitationStandardItems.find(
              (s) => s.id === selectedStandardItemId,
            );
            setSelectedStandardItem(newSelectedStandardItem);
          }}
          onBlur={() => {
            setDirtyFields((prev) => uniq([...prev, 'standardItem']));
          }}
          errorMessage={
            dirtyFields?.includes('standardItem') && standardItemError
              ? standardItemError
              : undefined
          }
        />
      </div>
      <StandardItemMakeModelPanel />
    </div>
  );
};

AddSopLine.propTypes = {
  contractHeader: PropTypes.shape(Object).isRequired,
};

export default AddSopLine;
