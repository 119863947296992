import { gql } from '@apollo/client';

export const GET_MULTIPLE_OPTIONS = gql`
  query GetMultipleOptions($options: [OptionType!]!) {
    getMultipleOptions(options: $options) {
      id
      label
      value
      type
      status
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const GET_SOLICITATIONS = gql`
  query getSolicitations(
    $filters: [Filter!]!
    $order: OrderBy
    $offset: Float
    $limit: Float
  ) {
    getSolicitations(
      filters: $filters
      order: $order
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        solicitationType
        solicitationNumber
        purchaseTypeCode
        status
        title
        contractYear
        bidType
        description
        samUrl
        createdUser {
          firstName
          lastName
        }
        updatedUser {
          firstName
          lastName
        }
        solicitationPeriods {
          audience
          startDate
          endDate
          periodType
        }
        programs {
          program
          programInfo {
            title
            code
          }
        }
        contracts {
          id
          solicitationId
          contractUPiid
          contractYear
          renewalYear
          awardedDate
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_SOLICITIATION_WITH_LINES = gql`
  query GetSolicitationById($id: Float!) {
    getSolicitationById(id: $id) {
      id
      title
      status
      prevStatus
      contractYear
      bidType
      solicitationType
      solicitationNumber
      programs {
        id
        program
        solicitationLines {
          id
          estimatedQty
          estimatedFleetQty
          standardItem {
            id
            standardItemNumber
            title
          }
        }
      }
    }
  }
`;

export const GET_SOLICITIATION_LINES = gql`
  query getSolicitationLines(
    $solicitationID: Float!
    $offset: Float
    $limit: Float
  ) {
    getSolicitationLines(
      solicitationID: $solicitationID
      offset: $offset
      limit: $limit
    ) {
      rows {
        id
        solicitationProgram {
          solicitation {
            contractYear
          }
        }
        estimatedQty
        estimatedFleetQty
        standardItem {
          standardItemNumber
          title
        }
      }
      count
      hasMore
    }
  }
`;

export const GET_SOLICITATION_TYPE_AHEAD_OPTIONS = gql`
  query getSolicitationTypeAheadOptions(
    $key: String!
    $year: String!
    $search: String!
  ) {
    getSolicitationTypeAheadOptions(key: $key, year: $year, search: $search)
  }
`;

export const CREATE_SOLICITATION = gql`
  mutation CreateSolicitation($solicitationinput: CreateSolicitationInput!) {
    createSolicitation(solicitationinput: $solicitationinput) {
      id
      solicitationNumber
    }
  }
`;

export const UPDATE_SOLICITATION = gql`
  mutation UpdateSolicitation($solicitationinput: CreateSolicitationInput!) {
    updateSolicitation(solicitationinput: $solicitationinput) {
      id
    }
  }
`;

export const UPDATE_SOLICITATION_LINE = gql`
  mutation updateSolicitationLine($inputs: SolicitationLineInput!) {
    updateSolicitationLines(inputs: $inputs)
  }
`;
export const ADD_OR_UPDATE_SOLICITATION_PERIOD = gql`
  mutation addOrUpdateSolicitationPeriod(
    $solicitationPeriodInput: SolicitationPeriodInput!
  ) {
    addOrUpdateSolicitationPeriod(
      solicitationPeriodInput: $solicitationPeriodInput
    ) {
      id
      description
      title
      status
      contractYear
      samUrl
      solicitationType
      purchaseTypeCode
      solicitationNumber
      solicitationPeriods {
        id
        startDate
        endDate
        audience
      }
    }
  }
`;
export const ADD_STANDARD_ITEMS_TO_SOLICITATION = gql`
  mutation AddOrUpdateSINSelections($selections: SolicitationSelectionsInput!) {
    addOrUpdateSINSelections(selections: $selections) {
      id
      solicitationNumber
      programs {
        program
        solicitationLines {
          standardItemId
        }
      }
    }
  }
`;

export const GET_BID = gql`
  query GetBidById($bidId: Float!) {
    getBidById(bidId: $bidId) {
      id
      vendorId
      solicitationPeriod {
        closeInDays
        startDate
        endDate
      }
      soliciation {
        id
        title
        status
        samUrl
        solicitationNumber
      }
      BidLines {
        id
        status
        scheduleLine
        reassignedFromBidLine {
          scheduleLine
        }
      }
    }
  }
`;

export const GET_BID_LINE = gql`
  query GetBidLine($id: Int!) {
    getBidLine(id: $id) {
      id
      bidId
      reassignedFromId
      status
      isIn1122Program
      shipmentDays
      shipmentJustification
      unitPrice
      invoicePrice
      msrp
      asePrice
      destinationCharge
      quantity
      solicitationLine {
        id
        estimatedQty
        estimatedFleetQty
      }
      scheduleLine
      makeCode
      modelCode
      chassisMakeCode
      chassisModelCode
      modelYear
      afpMake {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      afpModel {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      chassisMake {
        makeName
        makeCode
        approvalStatus
        fleetStatus
      }
      chassisModel {
        modelName
        modelCode
        approvalStatus
        fleetStatus
      }
      makeModelApproval {
        fleetApprovalStatus
      }
      standardItemId
      standardItem {
        id
        standardItemNumber
        title
        content {
          content
        }
        tags
      }
      colors {
        id
        vendorColorName
        vendorColorCode
        gsaColorCodeId
        standardPremiumCode
        price
      }
      equipmentCodes {
        id
        bidLineId
        associationId
        equipmentCode
        inputType
        comply
        value
        optionType
        maxQuantity
        unitPrice
        shipmentDays
        clarifications {
          id
          clarification
          clarificationType
        }
        ecRelations {
          id
          bidLineEcId
          associationId
          equipmentCode
          relationshipCode
          conflicts
        }
      }
      engines {
        id
        associationId
        equipmentCode
        cylinders
        engineLitres
        engineModel
        fuelTypeCodeId
        isFuelDataUnknown
        range
        rangeElectric
        deliveryRegionType
        deliveryStates
        isMetric
        isGPTKM
        mpgs {
          id
          fuelType
          type
          value
        }
        charging {
          id
          type
          isFastChargeCapable
          fastChargeTypes {
            id
            fastChargeType
            userEnteredType
            chargeOption
          }
          bkwh
        }
        clarification {
          clarification
          clarificationType
          id
        }
      }
      associatedLocations {
        id
        vendorAssemblyPointId
        vendorInspectionPointId
        percentageOfDomContent
      }
      clarifications {
        id
        bidLineId
        clarification
        clarificationType
      }
      bid {
        vendorId
        vendorDetail {
          vendorName
          uniqueEntityIdentifier
        }
        solicitationPeriod {
          closeInDays
          endDate
        }
      }
    }
  }
`;

export const GET_SOLICITATION_BY_ID_FOR_BID = gql`
  query GetSolicitationById($id: Float!) {
    getSolicitationById(id: $id) {
      id
      title
      status
      contractYear
      samUrl
      solicitationNumber
      solicitationType
      solicitationPeriods {
        periodType
        endDate
        closeInDays
        solicitationPeriodVendors {
          id
          vendorId
          audienceType
          vendorDetail {
            id
            vendorName
            uniqueEntityIdentifier
          }
        }
      }
      programs {
        id
        program
        programInfo {
          title
          code
        }
        solicitationLines {
          id
          standardItemId
          standardItem {
            id
            standardItemNumber
            title
            vehicleType
            vehicleTypeCode {
              code
              title
            }
            vehicleGroup {
              code
              title
            }
            status
            tags
          }
        }
      }
    }
  }
`;

export const GET_SOLICITATION_BY_ID = gql`
  query GetSolicitationById($id: Float!) {
    getSolicitationById(id: $id) {
      id
      description
      title
      status
      cronUpdatedAt
      dataSource
      prevStatus
      contractYear
      samUrl
      bidType
      solicitationType
      purchaseTypeCode
      solicitationNumber
      solicitationPeriods {
        id
        startDate
        endDate
        audience
        periodType
        closeInDays
        solicitationPeriodVendors {
          id
          solicitationPeriodId
          vendorId
          audienceType
          vendorDetail {
            id
            vendorName
            uniqueEntityIdentifier
          }
        }
      }
      programs {
        id
        program
        programInfo {
          title
          code
        }
        solicitationLines {
          id
          estimatedQty
          estimatedFleetQty
          standardItemId
          standardItem {
            id
            standardItemNumber
            title
            vehicleType
            vehicleTypeCode {
              code
              title
            }
            vehicleGroup {
              code
              title
            }
            status
            tags
          }
        }
      }
      createdUser {
        id
        firstName
        lastName
        fullName
      }
      updatedUser {
        id
        firstName
        lastName
        fullName
      }
    }
  }
`;

export const GET_SOLICITATION_BY_ID_WITH_CONTRACTS = gql`
  query GetSolicitationById($id: Float!) {
    getSolicitationById(id: $id) {
      id
      description
      title
      status
      dataSource
      prevStatus
      contractYear
      samUrl
      bidType
      solicitationType
      purchaseTypeCode
      solicitationNumber
      solicitationPeriods {
        id
        startDate
        endDate
        audience
        periodType
        solicitationPeriodVendors {
          id
          solicitationPeriodId
          vendorId
          audienceType
          vendorDetail {
            id
            vendorName
            uniqueEntityIdentifier
          }
        }
      }
      programs {
        id
        program
        solicitationLines {
          id
          estimatedQty
          estimatedFleetQty
          standardItemId
          standardItem {
            id
            standardItemNumber
            title
            vehicleType
            status
          }
        }
      }
      contracts {
        id
        solicitationId
        vendorId
        contractUPiid
        contractYear
        awardCancelledDate
      }
    }
  }
`;

export const GET_SOLICITATION_PERIODS_BY_NUMBER = gql`
  query getSolicitationPeriodsByNumber($solicitationNumber: String!) {
    getSolicitationPeriodsByNumber(solicitationNumber: $solicitationNumber) {
      id
      title
      contractYear
      solicitationPeriods {
        id
        startDate
        endDate
        audience
        periodType
      }
    }
  }
`;

export const GET_CONTRACTS_BY_SOLICITATION_ID = gql`
  query getContractsBySolicitationId($solicitationId: Float!) {
    getContractsBySolicitationId(solicitationId: $solicitationId) {
      id: contractHeaderId
      contractYear
      solicitationId
    }
  }
`;

export const GET_FVS_OPTIONS_FOR_YEAR = gql`
  query GetFvsCodes($year: Float!, $includeOnlyCommentable: Boolean!) {
    getVehicleClassificationFVS(
      year: $year
      includeOnlyCommentable: $includeOnlyCommentable
    ) {
      id
      label
      value
      type
      uniqueKey
      currentYear
      parent
    }
  }
`;

export const SUBMIT_SOLICITATION_AS_NEW = gql`
  mutation SubmitSolicitation($solicitationId: Float!) {
    submitSolicitation(solicitationId: $solicitationId) {
      id
      solicitationNumber
    }
  }
`;

export const DELETE_SOLICITATION = gql`
  mutation DeleteSolicitationById(
    $solicitationId: Float!
    $forceDelete: String!
  ) {
    deleteSolicitationById(id: $solicitationId, forceDelete: $forceDelete) {
      id
      solicitationNumber
    }
  }
`;
export const DELETE_SOLICITATION_PERIOD = gql`
  mutation DeleteSolicitationPeriodById($id: Float!) {
    deleteSolicitationPeriodById(id: $id) {
      id
    }
  }
`;

export const RENEW_SOLICITATION = gql`
  mutation renewSolicitation($year: Float!, $input: RenewSolicitationInput!) {
    renewSolicitation(yearRenewTo: $year, renewalInput: $input) {
      id
      solicitationNumber
      contractYear
      status
      bidType
    }
  }
`;

export const GET_VENDORS = gql`
  query GetVendors(
    $filters: [Filter!]!
    $order: OrderBy
    $offset: Int
    $limit: Int
  ) {
    getFilteredVendors(
      offset: $offset
      limit: $limit
      order: $order
      filters: $filters
    ) {
      rows {
        id
        fleetVendorNumber
        vendorName
        uniqueEntityIdentifier
        vendorTypes {
          vendorTypeCode
        }
      }
    }
  }
`;

export const DELETE_ADDITIONAL_PERIODS_BY_SOLICITATION_ID = gql`
  mutation DeleteAdditionalPeriodsBySolicitationId($id: Float!) {
    deleteAdditionalPeriodsBySolicitationId(id: $id)
  }
`;

export const GET_ACTIVE_BIDDERS = gql`
  query GetActiveBidders($solicitationId: Float!) {
    getActiveBidders(solicitationId: $solicitationId) {
      id
      vendorName
    }
  }
`;
